export const getStagePointersPositions = (event, stage) => {
	stage.current.setPointersPositions(event);
	const pointerPosition = stage.current.getPointerPosition();
	const absolutePosition = {
		x: (pointerPosition.x - stage.current.x()) / stage.current.scaleX(),
		y: (pointerPosition.y - stage.current.y()) / stage.current.scaleY(),
	};

	return {
		x: absolutePosition.x,
		y: absolutePosition.y,
	};
};

export const getScaleFactor = (map, mapZoom) => {
	return Math.pow(2, map.getZoom() - mapZoom);
};

export const convetLatLngToPoints = (map, floorplanDetails) => {
	return map.latLngToLayerPoint([floorplanDetails.building.lat, floorplanDetails.building.long]);
};

export const getUpdatedContainerPoints = (map, floorplanDetails) => {
	const pts = convetLatLngToPoints(map, floorplanDetails);
	return map.layerPointToContainerPoint(pts);
};

export const getMousePosition = (stage) => {
	const pointerPosition = stage.getPointerPosition();
	const absolutePosition = {
		x: (pointerPosition.x - stage.x()) / stage.scaleX(),
		y: (pointerPosition.y - stage.y()) / stage.scaleY(),
	};

	return {
		x: absolutePosition.x,
		y: absolutePosition.y,
	};
};
