import Konva from 'konva';
import { drawText, drawArea, drawIcon, renameText, drawRoute, copyArea } from './Draw';
import {
	GEOMETRY_TYPE_ICON,
	GEOMETRY_TYPE_POLYGON,
	GEOMETRY_TYPE_ROUTE,
	GEOMETRY_TYPE_TEXT,
} from './consts';
import { konvaShapeEvents } from './components/Functions';

export var layer;
export var floorplanDetails;
export var editPermission;

/** * method to draw floorplan image ** */
export const drawFloorPlanImage = (layer, image, floorplanDetails, editPermissionOnSiteId) => {
	const oldImg = layer.find((node) => node.id() === floorplanDetails.id.toString());
	const floorplanArchived = !!floorplanDetails?.archivedBy;
	const imageObj = new Image();
	imageObj.src = image;

	if (oldImg.length > 0) {
		imageObj.onload = function() {
			oldImg[0].image(imageObj);
		};
		return oldImg[0];
	}
	const FloorplanImg = new Konva.Image({
		id: `${floorplanDetails.id}`,
    x: floorplanDetails.positionX || (floorplanDetails.id ? 0 : floorplanDetails.building.lat),
		y: floorplanDetails.positionY || (floorplanDetails.id ? 0 : floorplanDetails.building.long),
		width: floorplanDetails.width ? floorplanDetails.width : 500,
		height: floorplanDetails.height ? floorplanDetails.height : 500,
		rotation: floorplanDetails.rotation ? floorplanDetails.rotation : null,
		scaleX: floorplanDetails.scaleX ? floorplanDetails.scaleX : null,
		scaleY: floorplanDetails.scaleX ? floorplanDetails.scaleY : null,

		draggable: editPermissionOnSiteId && !floorplanArchived,
		listening: false,
		scalable: editPermissionOnSiteId && !floorplanArchived,
	});

	imageObj.onload = function() {
		FloorplanImg.image(imageObj);
	};
	return FloorplanImg;
};

/** * method to loop throw shapes and draw them by type ** */
export const drawShapes = (
	setHasChange,
	showModal,
	maplayer,
	shapes,
	mapfloorplanDetails,
	mapeditPermission,
	addToBulkSave,
) => {
	layer = maplayer;
	floorplanDetails = mapfloorplanDetails;
	editPermission = mapeditPermission;

	const sortedShapes = !!shapes.length ? sortShapesByIconsDescending(shapes) : [];
	!!sortedShapes &&
		sortedShapes.forEach((shape) => {
			let konvaShape = null;
			switch (shape.geometryType) {
				case GEOMETRY_TYPE_TEXT:
					drawText(setHasChange, showModal, shape, addToBulkSave);
					break;
				case GEOMETRY_TYPE_POLYGON:
					drawArea(setHasChange, shape, showModal);
					break;
				case GEOMETRY_TYPE_ICON:
					konvaShape = drawIcon(shape);
					break;
				case GEOMETRY_TYPE_ROUTE:
					konvaShape = drawRoute(shape);
					break;
			}
			if (konvaShape) {
				konvaShapeEvents(konvaShape, setHasChange);
			}
		});
};

const sortShapesByIconsDescending = (shapes) => {
	return shapes.sort((a, b) => {
		const aPriority =
			a.geometryType === GEOMETRY_TYPE_ICON || a.geometryType === GEOMETRY_TYPE_ROUTE;
		const bPriority =
			b.geometryType === GEOMETRY_TYPE_ICON || b.geometryType === GEOMETRY_TYPE_ROUTE;

		// Compare priorities
		if (aPriority !== bPriority) {
			return aPriority ? 1 : -1; // prioritize false over true
		} else {
			// If priorities are equal, maintain the original order
			return 0;
		}
	});
};

/** * method to add actions like right clcik, duble click to shape like text, area, icon ** */
export const addShapeActions = (shape, shapeType, setHasChange, showModal, addToBulkSave) => {
	shape.on('contextmenu', function(event) {
		// Show the contextMenu
		const offset = 35;
		event.evt.preventDefault();
		const contextMenu = document.getElementById(`context-menu-${shapeType}`);
		contextMenu.style.display = 'block';
		contextMenu.style.left = `${event.evt.clientX - offset}px`;
		contextMenu.style.top = `${event.evt.clientY - offset}px`;

		// Hide the context menu
		document.addEventListener('click', function hideContextMenu() {
			contextMenu.style.display = 'none';
			document.removeEventListener('click', hideContextMenu);
		});

		if (shapeType === 'text') {
			document.getElementsByClassName(`renameOption-${shapeType}`)[0].onclick = () => {
				renameText(setHasChange, showModal, shape, addToBulkSave);
				contextMenu.style.display = 'none';
			};

			document.getElementsByClassName(`deleteOption-${shapeType}`)[0].onclick = () => {
				contextMenu.style.display = 'none';
			};
		}

		if (shapeType === 'area') {
			document.getElementsByClassName(`copyOption-${shapeType}`)[0].onclick = () => {
				contextMenu.style.display = 'none';
				copyArea(showModal, shape);
			};

			document.getElementsByClassName(`deleteOption-${shapeType}`)[0].onclick = () => {
				contextMenu.style.display = 'none';
			};
		}
	});

	shape.on('dblclick dbltap', () => {
		if (shapeType === 'text' && shape.attrs.draggable) {
			renameText(setHasChange, showModal, shape, addToBulkSave);
		}
	});
};
