import React, { useContext, useEffect, useRef, useState } from 'react';
import { FloorplanService } from 'services/FloorplanService';
import {
	LoadingSpinner,
	Button,
	ToasterContext,
	Dropdown,
	LoadingModal,
	ToggleButton,
	Tooltip,
	EmptyState,
	emptyFiles,
} from 'componentsV2';
import { Canvas } from './Canvas';
import { Legends } from './Legends';
import useSelectedSites from 'hooks/useSelectedSites';
import './css/MapView.scss';
import { SiteInfo } from './SiteInfo';
import { useDispatch } from 'react-redux';
import {
	Layers,
	AreasList,
	MapInfoButtons,
	TextList,
	IconList,
	AreaAddEdit,
	IconEdit,
	ShapeContent,
	MapViewHeaderButtons,
} from './components';
import {
	MAP_LAYERS,
	MAP_SITE_INFO,
	UNSAVED_CHANGES_MODAL,
	MAP_AREA_Edit,
	MAP_AREA_ADD,
	GEOMETRY_TYPE_TEXT,
	GEOMETRY_TYPE_ICON,
	GEOMETRY_TYPE_ROUTE,
	MAP_SHAPE_CONTENT,
	MAP_ICON_EDIT,
	MAP_LOADER_MODAL,
	GEOMETRY_TYPE_POLYGON,
} from './';
import { checkPermission, checkPermissionOnDistrict } from 'utilities/permissions';
import { useSelector } from 'react-redux';
import { MapViewModals, CopyAreaConfirmationModal } from './modals';
import { MapsProvider } from '../Providers';
import { Intent } from '@blueprintjs/core';
import { PrintMap } from './Print/PrintMap';
import { SATELLITE_VIEW } from '../consts';
import html2canvas from 'html2canvas';

// The puppeteer service requests this page with search param capture=true, so it can be formatted for the screenshot
const checkIfCapture = () => {
	const url = new URL(window.location.href);
	const params = new URLSearchParams(url.search);
	return params.get('capture') === 'true';
};

export const MapView = ({ match, history, viewMode }) => {
	const isCapture = checkIfCapture();
	const [loading, setLoading] = useState(true);
	const mapboxAccessToken =
		'pk.eyJ1IjoibWFobW91ZGFiZGVsZmF0YWgiLCJhIjoiY2xmdXN1ZnlsMDBoNzNxcXVnNnNnbGd4bCJ9.x_LR3ECCPpf6q2cBEXlqDg';
	const baseZoomLevel = 19;
	const [siteId, setSiteId] = useState(match.params.siteid);
	const [floorplanId, setFloorPlanId] = useState(
		match.params.floorplanId ? parseInt(match.params.floorplanId) : null,
	);
	const [xToken, setXToken] = useState(match.params.xToken ? match.params.xToken : null);
	const transformer = useRef();
	const mapRef = useRef();
	const [currentFloorplan, setCurrentFloorplan] = useState(null);
	const [lastModifiedDateMessage, setLastModifiedDateMessage] = useState();
	const [stage, setStage] = useState();
	const [stageRef, setStageRef] = useState(null);
	const [layer, setLayer] = useState();
	const [optionToShow, setOptionToShow] = useState(null);
	const permissions = useSelector((state) => state.route.permissions);
	const editPermissionOnSiteId = checkPermission('maps_edit', permissions, siteId);
	const viewPermissionOnSiteId = checkPermission('maps_view', permissions, siteId);
	const editPermissionOnDistrict = checkPermissionOnDistrict('maps_edit');
	const [modalToShow, setModalToShow] = useState(null);
	const [mapListUpdated, setMapListUpdated] = useState(false);
	const [hasChange, setHasChange] = useState(false);
	const [allLegendList, setAllLegendList] = useState([]);
	const [legendListWithDescription, setLegendListWithDescription] = useState([]);
	const toaster = useContext(ToasterContext);
	const defaultSelectedSites = !!xToken ? [] : useSelectedSites();
	const [selectedSites, setSelectedSites] = useState([]);
	const [bulkSaveData, setBulkSaveData] = useState([]);
	const [addBtnDisabled, setAddBtnDisabled] = useState(false);
	const [freeDrawingMode, setFreeDrawingMode] = useState(null);
	const [refreshTextList, setRefreshTextList] = useState(false);
	const [refreshAeraList, setRefreshAreaList] = useState(false);
	const [selectedArea, setSelectedArea] = useState();
	const [selectedIcon, setSelecteIcon] = useState();
	const [reloadMap, setReloadMap] = useState(false);
	const [selectedShape, setSelectedShape] = useState(null);
	const [newFloorplanId, setNewFloorplanId] = useState(null);
	const [triggerShowHide, setTriggerShowHide] = useState(true);
	const [sourceAction, setSourceAction] = useState(null);
	const [areahasChange, setAreaHasChange] = useState(false);
	const [iconUpdated, setIconUpdated] = useState(false);
	const [availableSites, setAvailableSites] = useState([]); // sites that should be displayed in header menu
	const [selectedSite, setSelectedSite] = useState({});
	const [areaToCopy, SetAreaToCopy] = useState();
	const dispatch = useDispatch();

	const [siteToChange, setSiteToChange] = useState(null);
	const [siteChanged, setSiteChanged] = useState(false);
	const [mapZoom, setMapZoom] = useState(baseZoomLevel);
	const [printMode, setPrintMode] = useState(false);
	const [mapCanvas, setMapCanvas] = useState(null);
	const [printedCanvas, setPrintedCanvas] = useState(null);
	const [mapImageLoaded, setMapImageLoaded] = useState(false);
	const [floorplanChanged, setfloorPlanChanged] = useState(false);
	const [floorplanShapes, setFloorplanShapes] = useState([]);
	const [isEditMode, setIsEditMode] = useState(viewMode ? false : true);
	const [inEditMode, setInEditMode] = useState(false);
	const [nextModalToShow, setNextModalToShow] = useState(null);
	const [floorplanLatLong, setFloorplanLatLong] = useState({ lat: null, long: null });
	const [stageScale, setStageScale] = useState(null);

	const printMap = () => {
		if (!!xToken) {
			showHideAllHandler(false);
		}
		setTimeout(() => {
			// Remove the Transformer before print
			const transformer = layer.getStage().findOne('Transformer');
			transformer.hide();

			// Remove the Transformer for the selected Area before print
			if (selectedArea) {
				let areaShape = layer.getStage().findOne(`#${selectedArea.id}`);
				areaShape.stroke(areaShape.fill());
				areaShape.strokeWidth(0);
			}

			const mapContainer = document.querySelector('.map-container-style');
			html2canvas(mapContainer, {
				useCORS: true, // Use CORS to handle cross-origin images
				logging: true,
				allowTaint: false,
				taintTest: false,
			}).then((canvas) => {
				setMapCanvas(canvas);
				const canvasContainer = document.querySelector('.konvajs-content canvas');
				if (canvasContainer) {
					setPrintedCanvas(canvasContainer);
				}
				setPrintMode(true);
			});
		});
	};

	const addNewTextHandler = () => {
		setAddBtnDisabled(true);
		setFreeDrawingMode(GEOMETRY_TYPE_TEXT);
		stage.container().style.cursor = 'text';
	};

	useEffect(() => {
		if (!!xToken) {
			dispatch({
				type: 'SET_USER',
				payload: xToken,
			});
			dispatch({
				type: 'SET_USER_DATA',
				payload: {
					id: null,
					person: { email: null, name: { givenName: null, familyName: null } },
				},
			});
		}
	}, [xToken]);

	useEffect(() => {
		if (!!xToken && mapImageLoaded) {
			setTimeout(printMap, 5000);
		}
	}, [xToken, mapImageLoaded]);

	const resetFreeDrawing = () => {
		setFreeDrawingMode(null);
		setAddBtnDisabled(false);
		stage.container().style.cursor = 'default';
	};

	const toggleMapOption = (mapOption) => {
		if (
			optionToShow === mapOption &&
			(mapOption !== MAP_AREA_ADD && mapOption !== MAP_AREA_Edit)
		) {
			setOptionToShow(null);
		} else {
			if (mapOption === MAP_AREA_ADD) {
				setSelectedArea(null);
			}
			setOptionToShow(mapOption);
		}
	};

	const getStage = (stage, layer) => {
		setStage(stage);
		setLayer(layer);
	};

	const getLegends = (updateShapes = false) => {
		FloorplanService.getAllLegends().then((response) => {
			if (response.statusCode === 200) {
				let legendsWithDescription = response.data.filter(
					(item) => item.description !== null && item.description.trim() !== '',
				);
				setLegendListWithDescription(legendsWithDescription);
				setAllLegendList(response.data);
				if (updateShapes) {
					updateAreasShapesColors(response.data);
				}
			}
		});
	};

	const updateAreasShapesColors = (legendsColors) => {
		setFloorplanShapes((floorplanShapes) =>
			floorplanShapes.map((floorplanShape) => {
				if (
					floorplanShape.geometryType === GEOMETRY_TYPE_POLYGON &&
					floorplanShape.colorId
				) {
					const legendColor = legendsColors.find(
						(legendColor) => legendColor.id === floorplanShape.colorId,
					);
					if (legendColor) {
						return {
							...floorplanShape,
							color: legendColor.hex,
						};
					}
				}
				return floorplanShape;
			}),
		);
		setReloadMap((val) => !val);
		setRefreshAreaList((val) => !val);
	};

	const showHideAllHandler = (val) => {
		setTriggerShowHide(val);
	};

	const showModal = (shape = null, modalType) => {
		setModalToShow(modalType);
		SetAreaToCopy(shape);
	};

	const switchMapView = (view, map) => {
		switch (view) {
			case SATELLITE_VIEW:
				var tileLayer = L.tileLayer(
					`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`,
					{
						maxZoom: 22,
						tileSize: 512,
						zoomOffset: -1,
						opacity: 0.5,
					},
				);
				map.addLayer(tileLayer);
				break;

			default:
				// white background view
				map.eachLayer((layer) => {
					if (layer instanceof L.TileLayer) {
						map.removeLayer(layer);
					}
				});
				break;
		}
	};

	function getLastModified(dateString) {
		const inputDate = new Date(dateString);

		const currentDate = new Date();

		const timeDifference = currentDate - inputDate;

		const seconds = Math.floor(timeDifference / 1000);

		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		const months = Math.floor(days / 30);
		const years = Math.floor(months / 12);

		if (years > 0) {
			return `Last edited: ${years} year${years > 1 ? 's' : ''} ago`;
		} else if (months > 0) {
			return `Last edited: ${months} month${months > 1 ? 's' : ''} ago`;
		} else if (days > 0) {
			return `Last edited: ${days} day${days > 1 ? 's' : ''} ago`;
		} else if (hours > 0) {
			return `Last edited: ${hours} hour${hours > 1 ? 's' : ''} ago`;
		} else if (minutes > 0) {
			return `Last edited: ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
		} else {
			return 'Last edited: just now';
		}
	}

	useEffect(() => {
		loadMapDetails();
	}, [floorplanId]);

	useEffect(() => {
		getLegends();
		getAvailableSites();
	}, []);

	useEffect(() => {
		if (currentFloorplan) {
			setInEditMode(!currentFloorplan.dateArchived && isEditMode);
			enableDisableShapeDragabiltiy(!currentFloorplan.dateArchived && isEditMode);
		}
	}, [currentFloorplan, isEditMode]);

	const getAvailableSites = () => {
		FloorplanService.getAvailableSites().then((response) => {
			if (response.statusCode == 200) {
				setAvailableSites(response.data);
			}
		});
	};

	const loadMapDetails = async (id = null) => {
		setMapImageLoaded(false);
		let currentFloorPlan = floorplanId;

		if (id) {
			currentFloorPlan = id;
		}

		if (siteChanged) {
			currentFloorPlan = null;
		}

		const params = {
			siteId,
			sortKey: 'order',
			sortDir: 'ASC',
			floorplanId: currentFloorPlan,
		};

		FloorplanService.getFloorplansBySiteId(params)
			.then((response) => {
				if (response.statusCode === 200) {
					const initiateSite = [];
					initiateSite.push({
						label: response.data.length > 0 ? response.data[0]['building']['name'] : '',
						value: response.data.length > 0 ? response.data[0]['building']['id'] : '',
					});

					setSelectedSites(initiateSite);

					let floorplan;

					if (id !== null) {
						floorplan = response.data.find((floorplan) => floorplan.id === id);
						setFloorPlanId(floorplan.id);
						reloadMapsList();
					} else if (floorplanId !== null) {
						floorplan = response.data.find((floorplan) => floorplan.id === floorplanId);
					}

					if (!floorplan) {
						floorplan = response.data[0];
						setFloorPlanId(floorplan.id);
						setNewFloorplanId(floorplan.id);
					}

					const newShapes = floorplan.shapes.map((shape) => {
						if (
							shape.geometryType === GEOMETRY_TYPE_ICON ||
							shape.geometryType === GEOMETRY_TYPE_ROUTE
						) {
							shape.visible = false;
						} else {
							shape.visible = true;
						}
						return shape;
					});

					setFloorplanShapes(newShapes);
					setCurrentFloorplan(floorplan);

					if (floorplan.building) {
						setSelectedSite({
							id: floorplan.building.id,
							name: floorplan.building.name,
						});
					}

					if (floorplan.dateModified) {
						const message = getLastModified(floorplan.dateModified.date);
						setLastModifiedDateMessage(message);
					}
				}
			})
			.finally(() => {
				setLoading(false);
				setModalToShow(null);
				setSiteChanged(false);
				setfloorPlanChanged(false);
			});
	};

	const changeFloorplan = (floorplan) => {
		if (floorplan.id !== currentFloorplan.id) {
			if (hasChange) {
				showAlertModal();
				setNewFloorplanId(floorplan.id);
				setfloorPlanChanged(true);
			} else {
				setModalToShow(MAP_LOADER_MODAL);
				setFloorPlanId(floorplan.id);
			}
		}
	};

	const showAlertModal = () => {
		setModalToShow(UNSAVED_CHANGES_MODAL);
	};

	const closeModal = () => {
		setModalToShow(null);
	};

	const onClosePrintPage = () => {
		setReloadMap((oldValue) => !oldValue);
		setPrintMode(false);
	};

	const saveChangesHandler = async () => {
		let finalData = [];
		let floorplanImg = {};
		await layer.children.forEach((shape) => {
			if (shape instanceof Konva.Text) {
				if (
					bulkSaveData.some(
						(item) => item.id == shape.attrs.id && item.type == GEOMETRY_TYPE_TEXT,
					)
				) {
					finalData.push(
						setShapeData(
							shape.attrs,
							GEOMETRY_TYPE_TEXT,
							shape.attrs.x,
							shape.attrs.y,
							shape.attrs.rotation,
						),
					);
				}
			}

			if (shape instanceof Konva.Group || shape instanceof Konva.Image) {
				if (shape.attrs) {
					if (shape.attrs.image && !shape.attrs.shape) {
						if (
							bulkSaveData.some(
								(item) => item.id == shape.attrs.id && item.type == 'floorplan',
							)
						) {
							floorplanImg = setShapeData(
								shape.attrs,
								'floorplan',
								shape.attrs.x,
								shape.attrs.y,
								shape.attrs.rotation,
							);
						}
					} else if (
						shape.attrs.shape?.geometryType == GEOMETRY_TYPE_ICON ||
						shape.attrs.shape?.geometryType == GEOMETRY_TYPE_ROUTE
					) {
						const iconType = shape.attrs.shape.geometryType;
						if (
							bulkSaveData.some(
								(item) => item.id == shape.attrs.shape.id && item.type == iconType,
							)
						) {
							const iconShape = shape.children.find(
								(child) =>
									child.attrs.type === GEOMETRY_TYPE_ICON ||
									child.attrs.type === GEOMETRY_TYPE_ROUTE,
							);
							const rotation = iconShape
								? iconShape.attrs.rotation
								: shape.attrs.rotation;

							const shapeToPush = {
								...shape.attrs.shape,
								scaleX: iconShape.scaleX(),
								scaleY: iconShape.scaleY(),
							};
							finalData.push(
								setShapeData(
									shapeToPush,
									iconType,
									shape.attrs.x,
									shape.attrs.y,
									rotation,
								),
							);
						}
					}
				}
			}
		});

		await FloorplanService.saveBulkActions(currentFloorplan.id, finalData, floorplanImg)
			.then((response) => {
				toaster(
					'You have successfully saved your changes',
					Intent.SUCCESS,
					null,
					2000,
					false,
					'map-view-page',
				);
			})
			.catch((err) => {
				toaster(err.error?.description, Intent.DANGER, null, 2000, false, 'map-view-page');
			})
			.finally(() => {
				setHasChange(false);
				newFloorplanId && setFloorPlanId(newFloorplanId);
				setNewFloorplanId(null);
				closeModal();
				setRefreshTextList((oldValue) => !oldValue);

				if (siteToChange) {
					changeSite();
				} else if (floorplanChanged) {
					setModalToShow(MAP_LOADER_MODAL);
				}
			});
		return true;
	};

	const discardChangesHandler = () => {
		newFloorplanId && setFloorPlanId(newFloorplanId);
		setNewFloorplanId(null);
		setHasChange(false);
		setReloadMap((oldValue) => !oldValue);
		closeModal();
		if (siteToChange) {
			changeSite();
		} else if (floorplanChanged) {
			setModalToShow(MAP_LOADER_MODAL);
		}
		return true;
	};

	const setShapeData = (shape, type, x, y, rotation) => {
		let currentData = null;

		currentData = {
			id: shape.id,
			title: shape.text,
			type: type,
			position_x: x,
			position_y: y,
		};

		if (shape.fill) {
			currentData['fill'] = shape.fill;
		}
		if (shape.fontsize) {
			currentData['fontsize'] = shape.fontSize;
		}
		if (rotation) {
			currentData['angle'] = parseInt(rotation);
		}

		if (shape.padding) {
			currentData['padding'] = shape.padding;
		}

		if (shape.skewX || shape.skewY) {
			currentData['shape_skew_x'] = shape.skewX;
			currentData['shape_skew_y'] = shape.skewY;
		}

		if (shape.scaleX || shape.scaleY) {
			currentData['shape_scale_x'] = shape.scaleX;
			currentData['shape_scale_y'] = shape.scaleY;
		}

		if (shape.width) {
			currentData['width'] = shape.width;
		}

		if (shape.height) {
			currentData['height'] = shape.height;
		}

		return currentData;
	};

	const reloadMapsList = (source = null) => {
		setSourceAction(source);
		setMapListUpdated((oldValue) => !oldValue);
	};

	const addToBulkSave = (shapeId, geometryType, action) => {
		const bulkSave = {
			id: shapeId,
			type: geometryType,
		};

		if (!bulkSaveData.some((item) => item.id == shapeId && item.type == geometryType)) {
			bulkSaveData.push(bulkSave);
			setBulkSaveData(bulkSaveData);
		}
	};

	const mapToaster = (message, intent) => {
		toaster(message, intent, null, 2000, false, 'map-view-page');
	};

	const onChangeSiteHandler = (site) => {
		if (hasChange) {
			showAlertModal();
			setSiteToChange(site);
		} else {
			changeSite(site);
		}
		document.getElementsByClassName('map-options-buttons')[0].style.display = 'flex'; //make sure menu buttons appers
	};

	const changeSite = (site) => {
		setSiteChanged(true);
		let newSite = site || siteToChange;
		setFloorPlanId(null);
		setSiteId(newSite.id);
		setSelectedSite(newSite);
		setOptionToShow(null);
		setModalToShow(MAP_LOADER_MODAL);
		setSiteToChange(null);
	};

	const enableDisableShapeDragabiltiy = (draggableStatus) => {
		draggableStatus === false
			? transformer?.current
				? transformer.current.nodes([])
				: null
			: null;
		if (layer?.children) {
			layer.children.forEach((item) => {
				if (item instanceof Konva.Text) {
					item.draggable(draggableStatus);
					item.listening(draggableStatus);
				}
				if (item instanceof Konva.Group) {
					if (item.attrs.shape?.icon) {
						item.draggable(draggableStatus);
					}
				}
			});
		}
	};

	const toggleEditMode = () => {
		setIsEditMode((oldValue) => !oldValue);
		if (isEditMode) {
			if (hasChange) {
				showAlertModal();
				//setNewFloorplanId(floorplanId);
			}
			enableDisableShapeDragabiltiy(false);
			toaster(
				'You have successfully turned off edit mode',
				Intent.SUCCESS,
				null,
				2000,
				false,
				'map-view-page',
			);
		} else {
			enableDisableShapeDragabiltiy(true);
			toaster(
				'You have successfully turned on edit mode',
				Intent.SUCCESS,
				null,
				2000,
				false,
				'map-view-page',
			);
		}
	};

	const ToggleEditModeButton = (
		<ToggleButton
			checked={inEditMode}
			label={inEditMode ? 'Turn off edit mode' : 'Turn on edit mode'}
			onChange={toggleEditMode}
			disabled={currentFloorplan?.dateArchived}
		/>
	);

	if (!loading && !currentFloorplan) {
		return (
			<EmptyState
				header="Map doesn't exist"
				className="map-view-empty-state"
				icon={emptyFiles}
			/>
		);
	}
	return (
		<div
			className={`map-container ${!!xToken ? 'pdfMode' : ''} ${
				optionToShow ? 'card-opened' : ''
			}`}
		>
			{loading && <LoadingSpinner className="mt-3" />}
			{modalToShow == MAP_LOADER_MODAL && (
				<LoadingModal
					isOpen={true}
					loading={true}
					customClassName="map-loader"
					text="Loading..."
				/>
			)}
			{modalToShow === 'CopyArea' && (
				<CopyAreaConfirmationModal
					isOpen={true}
					handleClose={closeModal}
					selectedShape={areaToCopy}
					floorplanDetails={currentFloorplan}
					toggleMapOption={toggleMapOption}
					setSelectedArea={setSelectedArea}
					setSelectedShape={setSelectedShape}
					setFloorplanShapes={setFloorplanShapes}
					showModal={showModal}
					layer={layer}
				/>
			)}
			{!loading && !printMode && (
				<MapsProvider
					reloadDetails={reloadMapsList}
					history={history}
					toggleMapOption={toggleMapOption}
					defaultSelectedSites={defaultSelectedSites}
					selectedSites={selectedSites}
					setSelectedSites={setSelectedSites}
					loadMapDetails={loadMapDetails}
					layer={layer}
					transformer={transformer}
					setRefreshTextList={setRefreshTextList}
					currentFloorplan={currentFloorplan}
					setCurrentFloorplan={setCurrentFloorplan}
					setIsEditMode={setIsEditMode}
					mapHasChanges={hasChange}
					setRefreshAreaList={setRefreshAreaList}
					setMapViewModalToShow={setModalToShow}
					setNextModalToShow={setNextModalToShow}
					editPermissionOnSiteId={editPermissionOnSiteId}
				>
					<MapViewModals
						modalToShow={modalToShow}
						closeModal={closeModal}
						discardChangesHandler={discardChangesHandler}
						saveChangesHandler={saveChangesHandler}
						nextModalToShow={nextModalToShow}
						setNextModalToShow={setNextModalToShow}
					/>
					{!isCapture && (
						<div className="map-header">
							<div className="map-header-left">
								<Dropdown
									value={selectedSite}
									options={availableSites}
									optionLabel="name"
									optionValue="id"
									onChange={onChangeSiteHandler}
								/>
								<MapViewHeaderButtons
									floorplan={currentFloorplan}
									printMap={printMap}
								/>
								{editPermissionOnSiteId &&
									(currentFloorplan?.dateArchived ? (
										<Tooltip
											tooltipTitle={
												<>
													Edit mode is disabled for <br />
													archived maps. If you need to <br />
													make changes, please create a <br />
													new map or unarchive this one.
												</>
											}
											theme="dark"
										>
											{ToggleEditModeButton}
										</Tooltip>
									) : (
										ToggleEditModeButton
									))}
							</div>
							<div className="map-header-right">
								{hasChange && (
									<Button
										text="Save"
										intent="default"
										onClick={saveChangesHandler}
									/>
								)}
								<label className="last-edit">{lastModifiedDateMessage}</label>
							</div>
						</div>
					)}
					<div className="map-footer">
						<Legends
							getLegends={getLegends}
							allLegendList={allLegendList}
							legendListWithDescription={legendListWithDescription}
							editPermissionOnDistrict={editPermissionOnDistrict}
							isCapture={isCapture}
						/>
					</div>
					<Canvas
						setStageScale={setStageScale}
						floorplanDetails={currentFloorplan}
						floorplanLatLong={floorplanLatLong}
						onStageCreation={getStage}
						setStageRef={setStageRef}
						setHasChange={setHasChange}
						addToBulkSave={addToBulkSave}
						baseZoomLevel={baseZoomLevel}
						freeDrawingMode={freeDrawingMode}
						resetFreeDrawing={resetFreeDrawing}
						setRefreshTextList={setRefreshTextList}
						setRefreshAreaList={setRefreshAreaList}
						editPermissionOnSiteId={editPermissionOnSiteId}
						reloadMap={reloadMap}
						toggleMapOption={toggleMapOption}
						setSelectedArea={setSelectedArea}
						selectedIcon={selectedIcon}
						setSelecteIcon={setSelecteIcon}
						setSelectedShape={setSelectedShape}
						selectedShape={selectedShape}
						mapToaster={mapToaster}
						transformer={transformer}
						areahasChange={areahasChange}
						selectedArea={selectedArea}
						setReloadMap={setReloadMap}
						setAreaHasChange={setAreaHasChange}
						setIconUpdated={setIconUpdated}
						iconUpdated={iconUpdated}
						legendListWithDescription={legendListWithDescription}
						siteId={siteId}
						loadMapDetails={loadMapDetails}
						setMapImageLoaded={setMapImageLoaded}
						floorplanShapes={floorplanShapes}
						setFloorplanShapes={setFloorplanShapes}
						isEditMode={isEditMode}
						setIsEditMode={setIsEditMode}
						xToken={xToken}
						setMapZoom={setMapZoom}
						mapZoom={mapZoom}
					/>
					{!isCapture && (
						<div className="map-right-options">
							{stageRef?.current && (
								<MapInfoButtons
									triggerShowHide={triggerShowHide}
                  showHideAllHandler={showHideAllHandler}
									stageRef={stageRef}
									stageScale={stageScale}
									optionToShow={optionToShow}
									toggleMapOption={toggleMapOption}
								/>
							)}
							{optionToShow === MAP_SITE_INFO && (
								<SiteInfo
									siteId={siteId}
									toggleMapOption={toggleMapOption}
									closeModal={closeModal}
									modalToShow={modalToShow}
									setModalToShow={setModalToShow}
									loadMapDetails={loadMapDetails}
								/>
							)}
							{optionToShow === MAP_LAYERS && (
								<Layers
									siteId={siteId}
									editPermission={editPermissionOnSiteId}
									toggleMapOption={toggleMapOption}
									currentFloorplan={currentFloorplan}
									changeFloorplan={changeFloorplan}
									mapListUpdated={mapListUpdated}
									sourceAction={sourceAction}
									history={history}
									isEditMode={isEditMode}
									setModalToShow={setModalToShow}
									hasChange={hasChange}
									setHasChange={setHasChange}
								/>
							)}
							<AreasList
								floorplan={currentFloorplan}
								legends={legendListWithDescription}
								toggleMapOption={toggleMapOption}
								editPermission={editPermissionOnSiteId}
								viewPermissionOnSiteId={viewPermissionOnSiteId}
								setSelectedArea={setSelectedArea}
								setSelectedShape={setSelectedShape}
								optionToShow={optionToShow}
								triggerShowHide={triggerShowHide}
								layer={layer}
								siteId={siteId}
								refreshAeraList={refreshAeraList}
								floorplanShapes={floorplanShapes}
								setFloorplanShapes={setFloorplanShapes}
								isEditMode={isEditMode}
								setAreaToCopy={SetAreaToCopy}
								setModalToShow={setModalToShow}
							/>

							{(optionToShow === MAP_AREA_ADD || optionToShow === MAP_AREA_Edit) && (
								<AreaAddEdit
									toggleMapOption={toggleMapOption}
									floorplan={currentFloorplan}
									layer={layer}
									stage={stage}
									loadMapDetails={loadMapDetails}
									selectedArea={selectedArea}
									legendListWithDescription={legendListWithDescription}
									baseZoomLevel={baseZoomLevel}
									editPermissionOnSiteId={editPermissionOnSiteId}
									selectedShape={selectedShape}
									setOptionToShow={setOptionToShow}
									setAreaHasChange={setAreaHasChange}
									areahasChange={areahasChange}
									setSelectedArea={setSelectedArea}
									setSelectedShape={setSelectedShape}
									setRefreshAreaList={setRefreshAreaList}
									isEditMode={isEditMode}
									setFloorplanShapes={setFloorplanShapes}
									showModal={showModal}
									viewPermissionOnSiteId={viewPermissionOnSiteId}
								/>
							)}
							<TextList
								floorplan={currentFloorplan}
								siteId={siteId}
								toggleMapOption={toggleMapOption}
								editPermission={editPermissionOnSiteId}
								addNewTextHandler={addNewTextHandler}
								addBtnDisabled={addBtnDisabled}
								refreshTextList={refreshTextList}
								triggerShowHide={triggerShowHide}
								optionToShow={optionToShow}
								layer={layer}
								floorplanShapes={floorplanShapes}
								setFloorplanShapes={setFloorplanShapes}
								isEditMode={isEditMode}
							/>

							<IconList
								optionToShow={optionToShow}
								floorplan={currentFloorplan}
								toggleMapOption={toggleMapOption}
								editPermission={editPermissionOnSiteId}
								siteId={siteId}
								layer={layer}
								listUpdated={refreshTextList}
								triggerShowHide={triggerShowHide}
								mapToaster={mapToaster}
								transformer={transformer}
								floorplanShapes={floorplanShapes}
								setFloorplanShapes={setFloorplanShapes}
							/>
							{optionToShow === MAP_SHAPE_CONTENT && (
								<ShapeContent
									siteId={siteId}
									selectedShape={selectedShape}
									floorplan={currentFloorplan}
									editPermission={editPermissionOnSiteId}
									isEditMode={isEditMode}
								/>
							)}
							{optionToShow === MAP_ICON_EDIT && (
								<IconEdit
									toggleMapOption={toggleMapOption}
									closeModal={closeModal}
									editPermission={editPermissionOnSiteId}
									isEditMode={isEditMode}
									viewPermission={viewPermissionOnSiteId}
									shape={selectedShape}
									floorplan={currentFloorplan}
									mapToaster={mapToaster}
									layer={layer}
									transformer={transformer}
									setSelecteIcon={setSelecteIcon}
									selectedIcon={selectedIcon}
									setIconUpdated={setIconUpdated}
									iconUpdated={iconUpdated}
									viewPermissionOnSiteId={viewPermissionOnSiteId}
								/>
							)}
						</div>
					)}
				</MapsProvider>
			)}
			{!loading && printMode && (
				<PrintMap
					floorplan={currentFloorplan}
					setCurrentFloorplan={setCurrentFloorplan}
					printedCanvas={printedCanvas}
					mapCanvas={mapCanvas}
					legends={legendListWithDescription}
					floorplanShapes={floorplanShapes}
					onClose={onClosePrintPage}
					xToken={xToken}
				/>
			)}
		</div>
	);
};
