import React, { useState, useRef, useEffect, useCallback } from 'react';
import Konva from 'konva';
import './css/Canvas.scss';
import { drawFloorPlanImage, drawShapes } from './CanvasDrawFn';
import { EmptyTextModal, CopyAreaConfirmationModal, DeleteShapeModal } from './modals';
import { RightClickMenu } from './components';
import { enableTextDrawing, stopEventListener, cancleDrawArea, calculateLabelCenter } from './Draw';
import {
	GEOMETRY_TYPE_ICON,
	GEOMETRY_TYPE_ICONS_GROUP,
	GEOMETRY_TYPE_ROUTE,
	GEOMETRY_TYPE_LINE,
	GEOMETRY_TYPE_TEXT,
	MAP_AREA_Edit,
	DELETE_SHAPE_MODAL,
	MAP_ICON_EDIT,
	MAP_AREA_ADD,
} from './consts';
import { ShapeActionsList } from './Draw';
import {
	getStagePointersPositions,
	onDropShapeEvent,
	updateIcon,
	updateUnSavedArea,
} from './components/Functions';
import { useContext } from 'react';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { UnsavedChangesModal } from './modals/UnsavedChangesModal';
import { FeaturedIcon, Tooltip } from 'componentsV2';
import { useWindowDimension } from 'hooks';

export const Canvas = ({
	floorplanDetails,
	onStageCreation,
	setHasChange,
	baseZoomLevel,
	addToBulkSave,
	freeDrawingMode,
	resetFreeDrawing,
	setRefreshTextList,
	setRefreshAreaList,
	editPermissionOnSiteId,
	reloadMap,
	toggleMapOption,
	setSelectedArea,
	setSelectedShape,
	selectedShape,
	selectedIcon,
	setSelecteIcon,
	mapToaster,
	transformer,
	areahasChange,
	setAreaHasChange,
	setIconUpdated,
	iconUpdated,
	selectedArea,
	legendListWithDescription,
	siteId,
	loadMapDetails,
	setMapImageLoaded,
	floorplanShapes,
	setFloorplanShapes,
	isEditMode,
	setIsEditMode,
	setMapZoom,
	setReloadMap,
	setStageRef,
	setStageScale,
}) => {
	const areahasChangeRef = useRef(areahasChange);
	const iconHasChangeRef = useRef(iconUpdated);
	const selectedAreaRef = useRef(selectedArea);
	const isEditModeRef = useRef(isEditMode);
	const [shapTypeforUnsaved, setShapeTypeForUnsaved] = useState();
	var stage = useRef();
	var layer = useRef();
	const containerRef = useRef(null);
	const floorplanArchived = !!floorplanDetails?.archivedBy;

	const [modalToShow, setModalToShow] = useState();
	const [isMapImageLock, setIsMapImageLock] = useState(true);
	const toaster = useContext(ToasterContext);
	const [stageInitialized, setStageInitialized] = useState(false);

	const { height: windowsHeight, width: windowsWidth } = useWindowDimension();
	useEffect(() => {
		// resize map stage when resizing screen width/height
		updateStageHeightWidth();
	}, [windowsHeight, windowsWidth]);

	useEffect(() => {
		areahasChangeRef.current = areahasChange;
	}, [areahasChange]);

	useEffect(() => {
		if (!isEditMode && layer?.current) {
			let floorplanImge = layer.current.find(
				(node) => parseInt(node.id()) === floorplanDetails.id,
			)[0];
			setIsMapImageLock(true);
			if (floorplanImge) {
				floorplanImge.listening(false);
			}
		}
	}, [isEditMode]);

	useEffect(() => {
		iconHasChangeRef.current = iconUpdated;
	}, [iconUpdated]);

	useEffect(() => {
		selectedAreaRef.current = selectedArea;
	}, [selectedArea]);

	useEffect(() => {
		isEditModeRef.current = isEditMode;
		if (!isEditMode && areahasChange) {
			setModalToShow('UnsavedChangesModal');
			setShapeTypeForUnsaved('area');
		} else if (!isEditMode && iconUpdated) {
			setModalToShow('UnsavedChangesModal');
			setShapeTypeForUnsaved('icon');
		}
	}, [isEditMode]);

	const enableDisableShapeDragabiltiy = (draggableStatus) => {
		layer.current.children.forEach((item) => {
			if (item instanceof Konva.Text) {
				item.draggable(draggableStatus);
			}
			// if (item instanceof Konva.Image) {
			// 	item.draggable(draggableStatus);
			// }
			if (item instanceof Konva.Group) {
				if (!(item.children[0] instanceof Konva.Line)) {
					item.draggable(draggableStatus);
				}
			}
		});
	};

	const initials = () => {
		setMapZoom(baseZoomLevel);

		stage.current = new Konva.Stage({
			container: containerRef.current,
			width: window.innerWidth,
			height: window.innerHeight - 60,
			x: 0,
			y: 0,
			position: 'relative',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			strokeWidth: 0,
		});

		layer.current = new Konva.Layer();
		stage.current.add(layer.current);
		//add stage events
		stageEvents();
		onStageCreation(stage.current, layer.current);
		setStageRef(stage);

		// Zooming with Mouse Wheel
		stage.current.on('wheel', (e) => {
			e.evt.preventDefault();

			const oldScale = stage.current.scaleX();
			const pointer = stage.current.getPointerPosition();

			const mousePointTo = {
				x: (pointer.x - stage.current.x()) / oldScale,
				y: (pointer.y - stage.current.y()) / oldScale,
			};

			// Adjust the scale (zoom level)
			const scaleBy = 1.05;
			const newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

			stage.current.scale({ x: newScale, y: newScale });

			const newPos = {
				x: pointer.x - mousePointTo.x * newScale,
				y: pointer.y - mousePointTo.y * newScale,
			};

			stage.current.position(newPos);
			stage.current.batchDraw();

			setStageScale(newScale);
		});

		// Panning with Mouse Drag
		stage.current.on('mousedown touchstart', () => {
			stage.current.draggable(true); // Enable panning (dragging)
		});

		stage.current.on('mouseup touchend', () => {
			stage.current.draggable(false); // Disable dragging when mouse button or touch ends
		});
	};

	const mainTransformer = () => {
		const mainTr = new Konva.Transformer({
			rotateEnabled: true,
			resizeEnabled: true,
			anchorSize: 10,
			borderStroke: 'green',
		});
		layer.current.add(mainTr);
		return mainTr;
	};

	const attachTransformers = () => {
		const mainTrans = mainTransformer();

		layer.current.on('click tap', (e) => {
			const target = e.target;
			let activeShape = null;
			if (
				target.attrs.type === GEOMETRY_TYPE_ICONS_GROUP ||
				target.attrs.type === GEOMETRY_TYPE_ROUTE
			) {
				activeShape = target.attrs ? target.attrs.shape : null;
				updateShapeLayer(activeShape);
				if (target.attrs.type === GEOMETRY_TYPE_ICONS_GROUP) {
					// Icon shape is selected then it should open information card and make it not resizable
					mainTrans.resizeEnabled(false);
					toggleMapOption(MAP_ICON_EDIT);
					editPermissionOnSiteId && isEditModeRef.current
						? (document.getElementsByClassName('map-options-buttons')[0].style.display =
								'none')
						: null;
				} else {
					// Route shape is selected then we should close information card and make it resizable
					mainTrans.resizeEnabled(true);
					toggleMapOption(null);
					editPermissionOnSiteId && isEditModeRef.current
						? (document.getElementsByClassName('map-options-buttons')[0].style.display =
								'flex')
						: null;
				}
			} else if (target instanceof Konva.Line) {
				activeShape = target.attrs ? target.attrs.shape : null;
				updateShapeLayer(activeShape);
			} else {
				if (target instanceof Konva.Text) {
					activeShape = target.attrs;
					activeShape.geometryType = GEOMETRY_TYPE_TEXT;
					updateShapeLayer(activeShape);
				}
				mainTrans.resizeEnabled(true);
			}
			if (editPermissionOnSiteId && isEditModeRef.current) {
				mainTrans.nodes([target]);
			}
		});

		mainTrans.on('transform', (e) => {
			setHasChange(true);
			//attach icon to transformer to only rotate icon
			const target = e.target;
			const shapeInfo = getShapeDetails(e.target);
			addToBulkSave(shapeInfo.id, shapeInfo.type, 'dragging');

			// if the shape is icon then transform only the inside icon.
			if (target.attrs.type === GEOMETRY_TYPE_ICONS_GROUP) {
				const icon = target.parent.children.filter(
					(item) => item.attrs.type === GEOMETRY_TYPE_ICON,
				);
				mainTrans.nodes([icon[0]]);
			}
		});

		stage.current.on('click', function(e) {
			// when click on empty space of the stage remove transformare
			if (e.target === stage.current) {
				mainTrans.detach();
				layer.current.activeShape = null;
				layer.current.draw();
			}

			// remove the transformare if Area is selected
			if (e.target && e.target.className && e.target.className.indexOf('Line') !== -1) {
				if (iconHasChangeRef.current) {
					setModalToShow('UnsavedChangesModal');
					setShapeTypeForUnsaved('icon');
				}
				mainTrans.detach();
				if (e.evt.button === 0) {
					setSelectedArea(e.target.attrs?.shape);
					toggleMapOption(MAP_AREA_Edit);
				}
			} else if (
				e.target.attrs.type === GEOMETRY_TYPE_ICONS_GROUP ||
				e.target.attrs.type === GEOMETRY_TYPE_ROUTE
			) {
				if (selectedAreaRef.current) {
					let area = layer.current.find(
						(node) => parseInt(node.id()) === selectedAreaRef.current.id,
					)[0];
					area.stroke(area.attrs.fill);
					area.strokeWidth(3);
				}
				// document.getElementsByClassName('map-options-buttons')[0].style.display = 'flex';
				e.target.attrs.type === GEOMETRY_TYPE_ICONS_GROUP
					? setSelecteIcon(e.target.attrs?.shape)
					: setSelecteIcon(null);
				if (areahasChangeRef.current) {
					setModalToShow('UnsavedChangesModal');
					setShapeTypeForUnsaved('area');
				}
			} else if (e.target.attrs.type === 'anchor') {
				mainTrans.detach();
			}
		});
		transformer.current = mainTrans;
	};

	const updateShapeLayer = (activeShape) => {
		setSelectedShape(activeShape);
		layer.current.activeShape = activeShape;
	};

	// stage container events
	const stageEvents = useCallback(() => {
		const stageContainer = stage.current.container();
		stageContainer.tabIndex = 1;
		stageContainer.focus();
		stageContainer.addEventListener('keydown', onKeydownStageHandler);
		stageContainer.addEventListener('dragover', onDragOverStageHandler);
		stageContainer.addEventListener('drop', onDropStageHandler);
		stage.current.on('dragend', (e) => {
			if (e.target.attrs.type !== GEOMETRY_TYPE_LINE) {
				//detect all shapes movment for bulk save except for the area
				setHasChange(true);
			}
			const shapeInfo = getShapeDetails(e.target);
			addToBulkSave(shapeInfo.id, shapeInfo.type, 'dragging');
		});
	}, []);

	const onDragOverStageHandler = (e) => {
		e.preventDefault();
	};

	const getShapeDetails = (target) => {
		let shapeInfo = {
			id: null,
			type: '',
		};

		if (target instanceof Konva.Text) {
			shapeInfo = {
				type: 'text',
				id: target.attrs.id,
			};
		} else if (target instanceof Konva.Group || target instanceof Konva.Image) {
			if (
				target.attrs &&
				target.attrs.image &&
				target.attrs.type != 'icon' &&
				target.attrs.type != 'route'
			) {
				shapeInfo = {
					type: 'floorplan',
					id: parseInt(target.attrs.id),
				};
			} else if (
				target.attrs &&
				(target.attrs.shape?.geometryType == GEOMETRY_TYPE_ICON ||
					target.attrs.shape?.geometryType == GEOMETRY_TYPE_ROUTE)
			) {
				shapeInfo = {
					type:
						target.attrs.shape?.geometryType == GEOMETRY_TYPE_ROUTE
							? GEOMETRY_TYPE_ROUTE
							: GEOMETRY_TYPE_ICON,
					id: target.attrs.shape?.id,
				};
			}
		}
		return shapeInfo;
	};

	const onDropStageHandler = (e) => {
		e.preventDefault();
		if (editPermissionOnSiteId && !floorplanArchived && isEditModeRef.current) {
			const pointerPosition = getStagePointersPositions(e, stage);
			onDropShapeEvent(e, pointerPosition, setHasChange, showModal, addToBulkSave).then(
				(response) => {
					if (response.statusCode === 201) {
						// push added shape to floorplanShapes state
						setFloorplanShapes((oldShapes) => [...oldShapes, response.data.shape]);
						setRefreshTextList((oldValue) => !oldValue);
						toaster(
							response.data.message,
							Intent.SUCCESS,
							null,
							2000,
							false,
							'map-view-page',
						);
					}
				},
			);
		}
	};

	const showModal = (shape = null, modalType) => {
		setModalToShow(modalType);
		setSelectedShape(shape);
	};

	const updateStageData = () => {
		stage.current.batchDraw();
	};

	const updateStageHeightWidth = () => {
		if ((windowsWidth > 0) & (windowsHeight > 0)) {
			stage.current.width(windowsWidth);
			stage.current.height(windowsHeight);
		}
	};

	useEffect(async () => {
		initials();
		updateStageData();

		const image = floorplanDetails.link;
		let FloorplanImg;
		if (image) {
			FloorplanImg = await drawFloorPlanImage(
				layer.current,
				image,
				floorplanDetails,
				editPermissionOnSiteId,
			);

			// Add a dotted gray border around the image
			const floorplanBorder = new Konva.Rect({
				x:
					floorplanDetails.positionX ||
					(floorplanDetails.id ? 0 : floorplanDetails.building.lat),
				y:
					floorplanDetails.positionY ||
					(floorplanDetails.id ? 0 : floorplanDetails.building.long),
				width: floorplanDetails.width ? floorplanDetails.width : 500,
				height: floorplanDetails.height ? floorplanDetails.height : 500,
				stroke: '#D9DCDD',
				strokeWidth: 3,
				dash: [10, 8],
			});

			layer.current.add(FloorplanImg);
			layer.current.add(floorplanBorder);

			// Calculate scale to fit the image into the stage
			const scaleX = stage.current.width() / FloorplanImg.width();
			const scaleY = stage.current.height() / FloorplanImg.height();
			const scale = Math.min(scaleX, scaleY); // Choose the smallest scale factor

			// Apply the scaling to the stage.current
			stage.current.scale({ x: scale, y: scale });

			// Move the stage to correct position relative to the floorplan image
			stage.current.position({
				x: -1 * FloorplanImg.x() * scale,
				y: -1 * FloorplanImg.y() * scale,
			});

			drawShapes(
				setHasChange,
				showModal,
				layer.current,
				floorplanShapes,
				floorplanDetails,
				editPermissionOnSiteId,
				addToBulkSave,
			);

			layer.current.draw();

			attachTransformers();

			if (isEditMode) {
				enableDisableShapeDragabiltiy(true);
			} else {
				enableDisableShapeDragabiltiy(false);
			}
			if (isMapImageLock) {
				FloorplanImg.listening(false);
			} else if (!isMapImageLock && !floorplanArchived) {
				FloorplanImg.listening(true);
			} else if (floorplanArchived) {
				FloorplanImg.listening(false);
				setIsMapImageLock(true);
			}
		}
		setMapImageLoaded(true);
		setStageInitialized(true);
	}, [floorplanDetails.id, reloadMap]);

	const onKeydownStageHandler = (e) => {
		const activeShape = layer.current.activeShape;
		// delete Shape
		if (e.keyCode === 46 && activeShape && editPermissionOnSiteId && isEditModeRef.current) {
			setModalToShow(DELETE_SHAPE_MODAL);
		}
	};

	useEffect(() => {
		if (freeDrawingMode == GEOMETRY_TYPE_TEXT) {
			enableTextDrawing(
				stage.current,
				resetFreeDrawing,
				setRefreshTextList,
				toaster,
				setHasChange,
				showModal,
				addToBulkSave,
				loadMapDetails,
			);
		}
	}, [freeDrawingMode]);

	const handleClose = () => {
		setModalToShow(null);
	};

	const handleUnsavedChanges = () => {
		if (areahasChangeRef.current) {
			if (selectedArea) {
				updateUnSavedArea(
					floorplanDetails.id,
					layer.current,
					stage.current,
					selectedArea,
					legendListWithDescription,
					mapToaster,
				);
				setAreaHasChange(false);
				!selectedIcon ? setIsEditMode(false) : null;
			} else {
				let newArea = layer.current.find((node) => node.name() === 'poly')[0];
				setSelectedArea(null);
				toggleMapOption(MAP_AREA_ADD);
			}
		} else if (iconHasChangeRef.current) {
			updateIcon(selectedIcon, floorplanDetails.id, layer.current, transformer, mapToaster);
			setIconUpdated(false);
			!selectedArea ? setIsEditMode(false) : null;
		}

		handleClose();
	};
	const disacardUnsavedCahnges = () => {
		if (areahasChangeRef.current) {
			if (selectedArea?.id) {
				let area = layer.current.find((node) => parseInt(node.id()) === selectedArea.id)[0];
				let selectedColor = legendListWithDescription.filter(
					(item) => item.id === area.attrs.shape.colorId,
				)[0];
				area.attrs.title = area.attrs.shape.title;
				area.fill('#' + selectedColor.hex);
				area.stroke('#' + selectedColor.hex);
				area.points(JSON.parse(area.attrs.shape.positions));
				area.x(null);
				area.y(null);

				if (area.attrs.shape?.showLabel) {
					let labelBorder = layer.current.find(
						(node) => node.id() === `labelBorder-${area.attrs.id}`,
					)[0];
					let labelGroup = layer.current.find(
						(node) => node.id() === `labelGroup-${area.attrs.id}`,
					)[0];

					let LabelCenter = calculateLabelCenter(area, labelBorder);
					labelGroup.x(LabelCenter[0]);
					labelGroup.y(LabelCenter[1]);
				}

				area.attrs.showLabel = area.attrs.shape.showLabel === true ? 1 : 0;
			} else {
				const currentArea = layer.current.find((node) => node.name() === 'poly')[0];
				cancleDrawArea(stage.current, currentArea);
			}

			// // stageCleaning

			stopEventListener('click', stage.current, 'addNewPointInstance');
			stopEventListener('mousedown', stage.current, 'handleAddNewArea');
			let anchors = layer.current.find((node) => node.name() === 'anchor');
			anchors.map((anchor) => {
				anchor.destroy();
			});
			document.getElementById('draw-cursor-container').classList.remove('draw-cursor');

			setAreaHasChange(false);
		} else if (iconHasChangeRef.current) {
			let oldData = floorplanDetails.shapes.filter((item) => item.id === selectedIcon.id)[0];
			if (!oldData) {
				oldData = layer.current.find((node) => parseInt(node.id()) === selectedIcon.id)[0]
					.attrs.shape;
			}
			let Icon = layer.current.find(
				(node) => node.id() === `iconBackground-${oldData.id}`,
			)[0];
			Icon.attrs.shape.title = oldData.Newtitle ? oldData.Newtitle : oldData.title;
			Icon.attrs.shape.showLabel = oldData.showLabel;
			setIconUpdated(false);
		}

		handleClose();
	};

	const handleChangeMapLockStatus = () => {
		setIsMapImageLock((oldValue) => !oldValue);
		let floorplanImge = layer.current.find(
			(node) => parseInt(node.id()) === floorplanDetails.id,
		)[0];

		if (isMapImageLock) {
			floorplanImge.listening(true);
			toaster(
				'You have successfully unlocked this map layer',
				Intent.SUCCESS,
				null,
				2000,
				false,
				'map-view-page',
			);
		} else {
			floorplanImge.listening(false);
			transformer.current.nodes([]);
			toaster(
				'You have successfully locked this map layer',
				Intent.SUCCESS,
				null,
				2000,
				false,
				'map-view-page',
			);
		}
	};
	return (
		<>
			<div
				ref={containerRef}
				style={{
					position: 'absolute',
					zIndex: 750,
					backgroundColor: '#F4F5F5',
					outline: 'none',
				}}
			/>
			<div className="canvas-container">
				<div className="map-left">
					<span className="map-title">{floorplanDetails.title}</span>
					{isEditMode && !floorplanArchived && (
						<Tooltip
							tooltipTitle={
								isMapImageLock
									? 'Layer is locked. Click to unlock.'
									: 'Layer is unlocked. Click to lock.'
							}
							theme={'light'}
							position={'right'}
						>
							<div className="lock-unlock-icon">
								<FeaturedIcon
									icon={isMapImageLock ? 'lock' : 'unlock'}
									type={isMapImageLock ? 'Error' : 'Primary'}
									size={'md'}
									shape={'roundedIcon'}
									onClick={handleChangeMapLockStatus}
								/>
							</div>
						</Tooltip>
					)}
				</div>
				{isEditMode && (
					<>
						<RightClickMenu type="text" setModalToShow={setModalToShow} />
						<RightClickMenu type="area" setModalToShow={setModalToShow} />
					</>
				)}

				<ShapeActionsList
					shape={selectedShape}
					setModalToShow={setModalToShow}
					editPermission={editPermissionOnSiteId}
					isEditMode={isEditMode}
				/>
				{modalToShow === 'EmptyText' && (
					<EmptyTextModal
						isOpen={true}
						handleClose={handleClose}
						selectedShape={selectedShape}
						floorplanDetails={floorplanDetails}
						setReloadMap={setReloadMap}
					/>
				)}
				{modalToShow === 'CopyArea' && (
					<CopyAreaConfirmationModal
						isOpen={true}
						handleClose={handleClose}
						selectedShape={selectedShape}
						floorplanDetails={floorplanDetails}
						toggleMapOption={toggleMapOption}
						setSelectedArea={setSelectedArea}
						setSelectedShape={setSelectedShape}
						setFloorplanShapes={setFloorplanShapes}
						showModal={showModal}
						layer={layer.current}
					/>
				)}
				{modalToShow === DELETE_SHAPE_MODAL && (
					<DeleteShapeModal
						shape={selectedShape}
						floorplanId={floorplanDetails.id}
						onClose={handleClose}
						layer={layer.current}
						transformer={transformer.current}
						mapToaster={mapToaster}
						setRefreshList={setRefreshTextList}
						setRefreshAreaList={setRefreshAreaList}
						toggleMapOption={toggleMapOption}
						siteId={siteId}
						loadMapDetails={loadMapDetails}
						setIconUpdated={setIconUpdated}
						setAreaHasChange={setAreaHasChange}
						setSelectedArea={setSelectedArea}
					/>
				)}
				{modalToShow === 'UnsavedChangesModal' && (
					<UnsavedChangesModal
						saveChangesHandler={handleUnsavedChanges}
						discardChangesHandler={disacardUnsavedCahnges}
						shapeType={shapTypeforUnsaved}
					/>
				)}
			</div>
		</>
	);
};
