import { useContext, useEffect, useState } from 'react';
import { Intent } from '@blueprintjs/core';
import { RapidAlarmService } from 'services';
import { safeDateToLuxon } from 'componentsV2';
import { ToasterContext } from 'pages/App';
import { PAST_ALARMS_PER_PAGE } from '../pages/RapidAlarms';

export const usePastAlarms = (
	perPage = PAST_ALARMS_PER_PAGE,
	dataLoaded = false, // To avoid multiple calls to the API
	filterationInitialState = [],
	selectedSites = [],
) => {
	const [loading, setLoading] = useState(!dataLoaded);
	const [pastAlarmList, setPastAlarmList] = useState([]);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [totalRows, setTotalRows] = useState(0);
	const [noDataAdded, setNoDataAdded] = useState(false);
	const [showEmptyStatus, setShowEmptyStatus] = useState(false);

	const [filterationData, setFilterationData] = useState({
		...filterationInitialState,
		sites: selectedSites,
		page: 1,
		perPage,
		sortKey: 'startDate',
		sortDir: 'desc',
	});
	const toaster = useContext(ToasterContext);

	useEffect(() => {
		if (!dataLoaded) {
			getPastAlarm();
		}
	}, [filterationData]);

	const getPastAlarm = () => {
		const startDate = filterationData.startDate
			? safeDateToLuxon(filterationData.startDate).toFormat('M/d/yyyy')
			: null;

		const endDate = filterationData.endDate
			? safeDateToLuxon(filterationData.endDate).toFormat('M/d/yyyy')
			: null;

		const params = {
			page: filterationData.page,
			perPage: filterationData.perPage,
			sortKey: filterationData.sortKey,
			sortDir: filterationData.sortDir,
			search: filterationData.search,
			buildingIds: filterationData.sites.map(site => site.value),
			startDate,
			endDate,
			alarmTypesIds: filterationData.alarmTypes
				? filterationData.alarmTypes.map(alarmType => alarmType.value)
				: [],
			modes: filterationData.alarmModes
				? filterationData.alarmModes.map(alarmMode => alarmMode.value)
				: [],
			is_911Cellular: filterationData.triggeredCellular ? 1 : 0,
		};

		RapidAlarmService.getAllPastAlarm(params)
			.then(response => {
				const isEmpty = response.data.length === 0;
				setPastAlarmList(response.data);
				setTotalRows(response._metadata.totalRecords);
				setShowEmptyStatus(isEmpty);
				setNoDataAdded(isFirstRender && isEmpty);
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				setIsFirstRender(false);
			});
	};

	return {
		showEmptyStatus,
		filterationData,
		setFilterationData,
		loading,
		setLoading,
		pastAlarmList,
		totalRows,
		noDataAdded,
	};
};
