import React, { useState, useEffect } from 'react';
import { Button, Tooltip, TooltipPosition, Badge } from 'componentsV2';
import { MAP_LAYERS, MAP_SITE_INFO, MAP_AREAS, MAP_TEXT_LIST, MAP_ICON_LIST } from '../';
import './css/MapInfoButtons.scss';
import { Icon } from '@blueprintjs/core';

const MapButton = ({
	title,
	isActive,
	disabled,
	icon,
	onClick,
	className,
	type = 'tertiary',
	theme = 'light',
}) => (
	<Tooltip tooltipTitle={title} theme={theme} position={TooltipPosition.LEFT}>
		<Button
			disabled={disabled}
			small
			icon={icon}
			iconColor={isActive ? 'primary' : ''}
			size="lg"
			type={type}
			intent="default"
			onClick={onClick}
			className={className}
		/>
	</Tooltip>
);

export const MapInfoButtons = ({
	triggerShowHide,
	showHideAllHandler,
	optionToShow,
	toggleMapOption,
	stageRef,
	stageScale,
}) => {
	const _stageScale = stageRef.current.scaleX();
	const [isScaleInitialized, setIsScaleInitiatlized] = useState(false);
	const [originalScale, setOriginalScale] = useState(null);
	const [zoomPercentage, setZoomPercentage] = useState(100);

	// The stage scale is 1 initially, but then adjusts itself right after the stage is rendered
	// This useEffect provides the logic to set the 'original' scale, which in our case is the post-init adjusted scale
	// Any changest to the scale after this is due to user input
	useEffect(() => {
		if (!isScaleInitialized) {
			if (_stageScale === 1) {
				return;
			} else {
				setOriginalScale(_stageScale);
				setIsScaleInitiatlized(true);
			}
		}
	}, [_stageScale]);

	// This useEffect is for updating the zoom percentage if the user scrolls in or out
	useEffect(() => {
		if (isScaleInitialized) {
			setZoomPercentage(Math.round((stageScale / originalScale) * 100));
		}
	}, [stageScale]);

	const zoomIn = () => {
		const stage = stageRef.current;
		const oldScale = stage.scaleX();
		const newScale = oldScale * 1.2;

		stage.scale({ x: newScale, y: newScale });
		stage.batchDraw(); // Redraw the stage

		setZoomPercentage(Math.round((newScale / originalScale) * 100));
	};

	const zoomOut = () => {
		const stage = stageRef.current;
		const oldScale = stage.scaleX();
		const newScale = oldScale / 1.2;

		stage.scale({ x: newScale, y: newScale });
		stage.batchDraw(); // Redraw the stage

		setZoomPercentage(Math.round((newScale / originalScale) * 100));
	};

	return (
		<div className="map-options-buttons">
			<div className="map-options-buttons-section">
				<MapButton
					isActive={optionToShow === MAP_SITE_INFO}
					title="Info"
					disabled={false}
					icon="info-sign"
					onClick={() => toggleMapOption(MAP_SITE_INFO)}
				/>
				<MapButton
					isActive={optionToShow === MAP_LAYERS}
					title="Layers"
					disabled={false}
					icon="layers"
					onClick={() => toggleMapOption(MAP_LAYERS)}
				/>
				<MapButton
					isActive={optionToShow === MAP_TEXT_LIST}
					title="Texts"
					disabled={false}
					icon="new-text-box"
					onClick={() => toggleMapOption(MAP_TEXT_LIST)}
				/>
				<MapButton
					isActive={optionToShow === MAP_AREAS}
					title="Areas"
					disabled={false}
					icon="draw"
					onClick={() => toggleMapOption(MAP_AREAS)}
				/>
				<MapButton
					isActive={optionToShow === MAP_ICON_LIST}
					title="Icons"
					disabled={false}
					icon="flag"
					onClick={() => toggleMapOption(MAP_ICON_LIST)}
				/>
			</div>
			<div className="map-options-buttons-section">
				<div className="Icon_ShowAll">
					<MapButton
						isActive={!triggerShowHide}
						title={
							!triggerShowHide ? (
								<>
									Icons and areas are shown. <br /> Click to hide.{' '}
								</>
							) : (
								<>
									Icons and areas are hidden. <br /> Click to show.{' '}
								</>
							)
						}
						disabled={false}
						icon={!triggerShowHide ? 'eye-open' : 'eye-off'}
						onClick={() => showHideAllHandler(!triggerShowHide)}
						type=""
						className={`eye-icon ${
							!triggerShowHide ? 'Icon_ShowAll-default' : 'Icon_ShowAll-danger'
						}`}
					/>
				</div>
				<div className="map-zoom-control">
					<div className="map-zoom-control-buttons">
						<button onClick={zoomIn}>
							<Icon icon="plus" />
						</button>
						<button onClick={zoomOut}>
							<Icon icon="minus" />
						</button>
					</div>
					<div className="zoom-percentage">
						<Badge type="White" size="sm" text={`${zoomPercentage}%`} />
					</div>
				</div>
			</div>
		</div>
	);
};
