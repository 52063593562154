import React, { useState, useEffect, useReducer, useContext } from "react";
import '../../App/Users/AccountManager/account-manager.scss'; // to move it to common place
import { NavLink } from 'react-router-dom';
import { Icon } from "@blueprintjs/core";
import { LoadingSpinner } from 'components/LoadingSpinner';
import AvatarInitials from '../../App/AvatarInitials'; // to move it to components folder
import { FirstRespondersService } from "services/FirstRespondersService";
import { Button } from "components/Button";
import LightTableComponent from 'components/LightTable';
import { ToasterContext } from "pages/App";
import { Intent } from '@blueprintjs/core';
import firstResponderImage from'images/first-responder.svg';
import MoreCard from 'components/MoreCard';
import { checkPermissionByCodeAndSites } from 'utilities/permissions';
import DeleteFirstResponderModal from "./Components/DeleteFirstResponderModal";
import { Helper } from 'utilities/helper';
import { PhoneWithExtension } from 'utilities/PhoneWithExtension';
import { useSelector } from "react-redux";
import { PageHeader } from "components/PageHeader";

/** Gets and displays responder agencies that have been added to the current building or buildings. When logged into a regular site */
const FirstResponderView = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [modalToShow, setModalToShow] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [reloadFirstResponder, setReloadFirstResponder] = useState(false);
    const toaster = useContext(ToasterContext);
    const permissions = useSelector( state => state.route.permissions );
    
    const firstResponderReducer = (firstResponder, action) => {
        switch (action.type) {
            case 'primaryContact':
                firstResponder['primaryContact'] = action.payload || {};
                if (firstResponder['primaryContact']['first_name'] && firstResponder['primaryContact']['family_name']) {
                    firstResponder['primaryContact']['fullName'] = firstResponder['primaryContact']['first_name'] + ' ' + firstResponder['primaryContact']['family_name'];
                }
            break;
            default:
                firstResponder[action.type] = action.payload;
            break;
        }
        return {
            responderId: firstResponder.responderId,
            data: firstResponder.data,
            allowedSites: firstResponder.allowedSites,
            contacts: firstResponder.contacts,
            primaryContact: firstResponder.primaryContact
        }
    }
    
    const [firstResponder, dispatchFirstResponder] = useReducer(firstResponderReducer, {
        responderId: props.match.params.id,
        data: null,
        allowedSites: [],
        contacts: [],
        primaryContact: null
    });

    const responderId = props.match.params.id;
    const avatarImg = <AvatarInitials initials={firstResponderImage} isImage={true} imageMode="profile"></AvatarInitials>;
    const headers = ['Name', 'Email', 'Phone'];
    const hiddenIndex = [3 , 4];
    const PrimaryContactTdNameRender = ({ name, contact }) => {
        return contact.isPrimary ? (
            <>
                {name} <span className="role-primary-label">(Primary)</span>
            </>
        ) : (
            <>
                {name}
            </>
        );
    }
    const renders = {
        name: (str, obj) => <PrimaryContactTdNameRender name={str} contact={obj} />,
        phone: (str, obj) => <PhoneWithExtension phone={str} extension={obj.extension}/>
    }
    
    useEffect(() => {    
        loadFirstResponder(responderId)
    }, [reloadFirstResponder]);

    const loadFirstResponder = async (id) => {
        setIsLoading(true);
        const params = { id: id };
        try {
            const response = await FirstRespondersService.getById(params);
            dispatchFirstResponder({ type: 'data', payload: response.data.primary_responder });
            dispatchFirstResponder({ type: 'primaryContact', payload: firstResponder.data.primary_contact });
            dispatchFirstResponder({ type: 'allowedSites', payload: firstResponder.data.allowed_sites });
            const hasEditPermission = checkPermissionByCodeAndSites('user_edit', permissions, firstResponder.data.allowed_sites_ids, 'some');
            setCanDelete(hasEditPermission);
            const transformedData = response.data.primary_responder.contacts.map((contact) => ({
                name: `${contact.first_name} ${contact.family_name}`,
                email: contact.email,
                phone: Helper.formatDigitsToPhone(contact.work_number),
                extension: contact.work_ext_number
            }));
            
            const staticItem = {
                name: response.data.primary_responder.primary_contact.first_name + ' ' + response.data.primary_responder.primary_contact.family_name,
                email: response.data.primary_responder.primary_contact.email,
                phone: Helper.formatDigitsToPhone(response.data.primary_responder.primary_contact.work_number),
                extension: response.data.primary_responder.primary_contact.work_ext_number,
                isPrimary: true
              };
            const contactsData = [staticItem, ...transformedData];
            dispatchFirstResponder({ type: 'contacts', payload: contactsData });
            
        } catch (err) {
            
        }
        setIsLoading(false);
        
    }
    

    const deleteBtnClicked = () => {
        setModalToShow('DeleteFirstResponderModal');
    }

    const DeleteFirstResponderHandler = sitesList => {
        setModalLoading(true);
        let selectedSitesIds = [];
        sitesList.map(site => {
            if(site.isSelected === true) {
                selectedSitesIds.push( site.id)
            }
        })
        FirstRespondersService.delete(responderId, selectedSitesIds).then((res) => {
            if (res.statusCode === 200) {
                toaster('First responder successfully deleted', Intent.SUCCESS);
                setModalLoading(false);
                setModalToShow(false);

                // if user is deleted i should be redirected to first responders list
                if ( res.data.all_sites_deleted ) {
                    const { history } = props;
                    history.push('/first-responders');
                } else {
                    setReloadFirstResponder(oldValue => !oldValue)
                }
            } else {
                toaster('Something went wrong', Intent.DANGER);
                setModalLoading(false);
            }
            
        })
        .catch((err) => {
            setModalLoading(false);
            toaster(err.error.description, Intent.DANGER)
        });
    }

    const goBackToFirstResponders = () => {
        const { history } = props;
        return history.push('/first-responders');
    }

    return (
        <>
            {modalToShow === 'DeleteFirstResponderModal' && (
                <DeleteFirstResponderModal
                    loading={modalLoading}
                    setModalToShow={setModalToShow}
                    DeleteFirstResponderAction={DeleteFirstResponderHandler}
                    firstResponder={firstResponder.data}
                />
            )}

            <div className='account-manager-general'>
                

                <div className='main-title-wrapper'>
                    <PageHeader
						title="First Responder Account"
						onBackBtnClick={goBackToFirstResponders}
					/>
                </div>


                { isLoading && (
                    <div className='main-info-wrapper_Loading'>
                        <LoadingSpinner />
                    </div>
                )}


                { !isLoading && (
                    <>
                        <div className='user-main-info-wrapper'>
                            
                            <div className='avatar-wrapper'>
                                {avatarImg} 
                            </div>

                            <div className='user-info-wrapper'>
                                
                                <div className='user-title'>
                                    <strong>{ firstResponder.data.name }</strong>
                                </div>


                                <div className='user-details'>
                                
                                    <div className='user-detail-box'>
                                        <span className='user-detail-subtitle'>
                                            Primary Contact Name
                                        </span>
                                        <p className='user-detail-value value-dark'>
                                            { firstResponder.primaryContact.fullName }
                                        </p>
                                    </div>

                                    <div className='user-detail-box'>
                                        <span className='user-detail-subtitle'>
                                            Primary Contact Email
                                        </span>
                                        <p className='user-detail-value value-dark'>
                                            { firstResponder.primaryContact.email }
                                        </p>
                                    </div>

                                    <div className='user-detail-box'>
                                        <span className='user-detail-subtitle'>
                                            Primary Phone Number
                                        </span>
                                        <div className='user-detail-value value-dark'>
                                            <PhoneWithExtension 
                                                phone={Helper.formatDigitsToPhone(firstResponder.primaryContact.work_number)} 
                                                extension={firstResponder.primaryContact.work_ext_number} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="user-actions-wrapper">
                            <div className="user-module-wrapper user-module-wrapper_OneColumn">
                                <div className="module-account">
                                    <h4 className="normal-font">Agency Information</h4>
                                    <h5>Sites Access</h5>
                                    <MoreCard items={firstResponder.allowedSites} visibleOptions={2} id={'firstresp'} />

                                    <h5>Address</h5>
                                    <div>{ firstResponder.data.address }</div>
                                    <div>{ firstResponder.data.city + ', ' + firstResponder.data.state }</div>
                                    <div>{ firstResponder.data.zip }</div>
                                    <hr className="line-separator semi-line"></hr>
                                    <h4 className="normal-font">Contacts Information</h4>
                                    <div className="">
                                        <LightTableComponent hiddenIndex={hiddenIndex} headers={headers} data={firstResponder.contacts} renders={renders} />
                                    </div>
                                    { canDelete && (
                                        <>
                                        <hr className="line-separator"></hr>
                                        <div className="delete-action-container">
                                            <h4>Delete Account</h4>
                                            <div className="delete-action-container-note">Delete to remove this first responder account from the system. This action cannot be undone.</div>
                                            <Button
                                                text="Delete"
                                                intent="secondary"
                                                onClick={deleteBtnClicked}
                                                disabled={!firstResponder.allowedSites || firstResponder.allowedSites.length === 0}
                                            ></Button>
                                        </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                
            </div>
        </>
    );
}

export default FirstResponderView;
