/* eslint-disable import/no-cycle */
import React from 'react';
import ComponentsV2 from 'componentsV2/ComponentsV2';
import SafetyPlanTemplatesDashboard from '../SafetyPlansV2/SafetyPlanTemplatesDashboard';
import AddTemplate from '../AddTemplate';
import Login from '../Login';
import AddPlan from '../AddTemplate/AddPlan';
import EditTemplate from '../EditTemplate';
import SsoLogin from '../SsoLogin';
import CloneTemplate from '../CloneTemplate';
import EditTemplateDetails from '../EditTemplateDetails';
import Drills from '../Drills';
import ManageDrillTypes from '../Drills/SubPages/ManageDrillTypes';
import Assess from '../Assess';
import EditAssessTemplate from '../EditAssessTemplate';
import ManageDrillLogFields from '../Drills/SubPages/ManageDrillLogFields';
import ManageDrillInstructions from '../Drills/SubPages/ManageDrillInstructions';
import ManageStateRequirements from '../Drills/SubPages/ManageStateRequirements';
import NewReportPage from '../Drills/SubPages/NewReportPage';
import ManageDistrictDrillRequirements from '../Drills/SubPages/ManageDistrictDrillRequirements';
import UpcomingDrills from '../Drills/SubPages/UpcomingDrills';
import OverdueDrills from '../Drills/SubPages/OverdueDrills';
import ManageEmailReminders from '../Drills/SubPages/ManageEmailReminders';
import SectionView from '../SectionView';
import SingleAssessment from '../SingleAssessment';
import ViewSchoolStats from '../Drills/SubPages/ViewSchoolStats';
import ViewMonthStats from '../Drills/SubPages/ViewMonthStats';
import PublicViewableSettings from '../Drills/SubPages/PublicViewableSettings';
import ComingSoon from '../ComingSoon';
import PublicDrillLogs from '../Drills/SubPages/PublicDrillLogs';
import OfficeEmailRecipients from '../Drills/SubPages/OfficeEmailRecipients';
import EmailPreferences from '../Drills/SubPages/EmailPreferences';
import AccountManager from './Users/AccountManager';
import ModuleAccount from './Users/AccountManager/ModuleAccount';
import SetPassword from '../Login/SetPassword';
import SetPasswordExpired from '../Login/SetPasswordExpired';
import ResetPasswordExpired from '../Login/ResetPasswordExpired';
import UploadBasic from './Users/AccountManager/UploadBasic';
import AwsImages from './AwsImages';
import TestPermission from './Users/TestPermission';
import PermissionCheckRoutes from './Users/PermissionCheckRoutes';
import DistrictSwitcher from '../DistrictSwitcher';
import { DistrictSwitcher as NewDistrictSwitcher } from '../DistrictSwitcherV2/DistrictSwitcher.jsx';
import CallList from '../CallList';
import { CallListV2, ContactsV2 } from '../CallListV2';
import FirstResponders from '../UserMangment/FirstResponders';
// eslint-disable-next-line import/no-cycle
import FirstResponderView from '../UserMangment/FirstResponders/FirstResponderView';
import { UsersCheckSite } from '../UserMangment/UsersCheckSite.jsx';
import UserRoles from '../UserMangment/UserRoles';
import Contacts from '../Contacts';
import { Flipchart } from '../Flipchart';
import { Print } from '../Flipchart/components/PrintAction/Print';
import { FlipchartDetails } from '../Flipchart/FlipchartDetails.jsx';
import { BindersDocuments } from '../BindersDocuments';
import { BinderDetails } from '../Binders';
import Administration from '../Administration';
import ScenarioDetails from '../Drills/SubPages/ScenarioDetails';
import { ScenarioDetailsV2 } from '../DrillsV2/components/Scenarios/ScenarioDetailsV2.jsx';
import { Maps, MapView, MapExport } from '../Maps';
import CoreTemplateDetails from '../SafetyPlansV2/Core/Details/CoreTemplateDetails';
import { DrillsV2 } from '../DrillsV2';
import DrillsSettings from '../DrillsV2/pages/DrillsSettings/DrillsSettings';
import { DrillLog } from '../DrillsV2/components/Dashboard/DrillLog.jsx';
import { Administration as DrillLogsAdmin } from '../DrillsV2/components/Administration/Administration.jsx';
import { ManageReminders } from '../DrillsV2/components/Reminders/ManageReminders.jsx';
import { FieldsList as DrillsFieldsList } from '../DrillsV2/components/FieldsList/FieldsList.jsx';
import { DrillTypes } from '../DrillsV2/components/DrillTypes/DrillTypes.jsx';
import { ManageDistrictDrillRequirements as ManageDDRequirementsV2 } from '../DrillsV2/components/Settings/StateRequirements/ManageDistrictDrillRequirements.jsx';
import ManageFirstResponderNotifications from '../DrillsV2/components/Settings/Responder/ManageFirstResponderNotifications.jsx';
import DistrictTemplateDetails from '../SafetyPlansV2/Districts/Details/DistrictTemplateDetails';
import { HomeCheckSiteInternal } from '../Home';
import { NewEMSComingSoon } from '../NewEMSComingSoon/NewEMSComingSoon';
import { FirstResponder } from '../FirstResponder/FirstResponder.jsx';
import { PasswordRecovery } from '../LoginV2/pages/PasswordRecovery/PasswordRecovery.jsx';
import {
	AlarmSettings,
	RapidAlarmDetails,
	RapidAlarms,
	PastAlarmDetails,
	ActiveAlarmDetails,
	RollCallDetails,
} from '../RapidAlarms/';
import { ManageStateDrillRequirements } from '../DrillsV2/components/Administration/ManageStateDrillRequirements.jsx';
import { ViewAssessment } from '../AssessV2/pages';
import Dashboard from '../Dashboard';
import { AssessV2 } from '../AssessV2';
import { CreateAlarmType } from '../RapidAlarms/Settings/CreateAlarmType/';
import { TemplateDetails } from '../AssessV2/pages/ViewTemplate/components/TemplateDetails';
import { ViewAlarmType } from '../RapidAlarms/Settings/ViewAlarmType/';
import { EditIntegrations } from '../RapidAlarms/Settings/Integrations/components/EditIntegrations';
import { TestConection } from '../RapidAlarms/Settings/Integrations/components/TestConnection';
import { CALLLIST_ROUTES } from 'utilities/constants';
import { SafetyPlansDistrictExport } from '../SafetyPlansV2/Export/SafetyPlansDistrictExport.jsx';

export const routes = [
	{
		link: '/',
		component: HomeCheckSiteInternal,
	},
	{
		link: '/emscomingsoon/:selectedPage',
		component: NewEMSComingSoon,
	},
	{
		link: '/first-responder',
		component: FirstResponder,
		parentPath: '/users',
	},
	{
		link: '/administration',
		component: props => <Administration {...props} />,
	},
	{
		link: '/safety-plans',
		component: SafetyPlanTemplatesDashboard,
	},
	{
		link: '/addtemplate',
		component: AddTemplate,
		parentPath: '/safety-plansV1',
	},
	{
		link: '/addplan/:id',
		component: AddPlan,
		parentPath: '/safety-plansV1',
	},
	{
		link: '/editplan/:id',
		component: AddPlan,
		parentPath: '/safety-plansV1',
	},
	{
		link: '/viewplan/:id',
		component: props => <AddPlan {...props} viewMode />,
		parentPath: '/safety-plansV1',
	},
	{
		link: '/clone/:id/:type',
		component: CloneTemplate,
		parentPath: '/safety-plansV1',
	},
	{
		link: '/editdetails/:id',
		component: EditTemplateDetails,
		parentPath: '/safety-plansV1',
	},
	{
		link: '/password-recovery',
		component: PasswordRecovery,
		protected: false,
	},
	{
		link: '/edit/:id',
		component: EditTemplate,
		parentPath: '/safety-plansV1',
	},
	{
		link: '/view/:id',
		component: props => <EditTemplate {...props} viewMode />,
		parentPath: '/safety-plansV1',
	},
	{
		link: '/login',
		component: Login,
		protected: false,
	},
	{
		link: '/sso/:id/:location?',
		component: SsoLogin,
		protected: false,
	},
	{
		link: '/clone/:id/:type',
		component: CloneTemplate,
		parentPath: '/safety-plansV1',
	},
	{
		link: '/drills',
		component: Drills,
	},
	{
		link: '/manageDrillTypes',
		component: ManageDrillTypes,
		parentPath: '/drills',
	},
	{
		link: '/assess',
		component: Assess,
	},
	{
		link: '/assessV2/assessment/:id',
		component: ViewAssessment,
		parentPath: '/assessV2',
	},
	{
		link: '/assessV2/assess/template/:id',
		component: TemplateDetails,
		parentPath: '/assessV2',
	},
	{
		link: '/assessV2',
		component: props => <AssessV2 {...props} tabId="index" />,
	},
	{
		link: '/assessV2/templates',
		component: props => <AssessV2 {...props} tabId="templates" />,
		parentPath: '/assessV2',
	},
	{
		link: '/assessV2/settings',
		component: props => <AssessV2 {...props} tabId="settings" />,
		parentPath: '/assessV2',
	},
	{
		link: '/assessment/:id',
		component: SingleAssessment,
		parentPath: '/assess',
	},
	{
		link: '/assess/editTemplate/:id',
		component: EditAssessTemplate,
		parentPath: '/assess',
	},
	{
		link: '/manageDrillLogFields',
		component: ManageDrillLogFields,
		parentPath: '/drills',
	},
	{
		link: '/manageDrillInstructions',
		component: ManageDrillInstructions,
		parentPath: '/drills',
	},
	{
		link: '/manageStateRequirements',
		component: ManageStateRequirements,
		parentPath: '/drills',
	},
	{
		link: '/newReportPage',
		component: NewReportPage,
		parentPath: '/drills',
	},
	{
		link: '/manageDistrictDrillRequirements',
		component: ManageDistrictDrillRequirements,
		parentPath: '/drills',
	},
	{
		link: '/upcomingDrills',
		component: UpcomingDrills,
		parentPath: '/drills',
	},
	{
		link: '/overdueDrills',
		component: OverdueDrills,
		parentPath: '/drills',
	},
	{
		link: '/manageDrillEmailReminders/:id',
		component: ManageEmailReminders,
		parentPath: '/drills',
	},
	{
		link: '/assessment/:id/sections/:sectionId',
		component: SectionView,
		parentPath: '/assess',
	},
	{
		link: '/viewSchoolStats/:id',
		component: ViewSchoolStats,
		parentPath: '/drills',
	},
	{
		link: '/viewMonthStats/:id',
		component: ViewMonthStats,
		parentPath: '/drills',
	},
	{
		link: '/publicViewableSettings',
		component: PublicViewableSettings,
		parentPath: '/drills',
	},
	{
		link: '/comingsoon/:feature',
		component: ComingSoon,
	},
	{
		link: '/publicDrillLogs/district/:districtId/building/:buildingId',
		component: PublicDrillLogs,
		parentPath: '/drills',
	},
	{
		link: '/officeEmailRecipients',
		component: OfficeEmailRecipients,
		parentPath: '/drills',
	},
	{
		link: '/emailPreferences',
		component: EmailPreferences,
		parentPath: '/drills',
	},
	{
		link: '/set-password/:email/:token',
		component: SetPassword,
	},
	{
		link: '/set-password-expired/',
		component: SetPasswordExpired,
	},
	{
		link: '/reset-password-expired/:email',
		component: ResetPasswordExpired,
	},
	{
		link: '/uploadbasic',
		component: UploadBasic,
	},
	{
		link: '/aws/images',
		component: AwsImages,
	},
	{
		link: '/testpermissions/:userId/:districtId?/:permission?',
		component: TestPermission,
	},
	{
		link: '/permissionscheckroutes/:userId/:districtId',
		component: PermissionCheckRoutes,
	},
	{
		link: '/switch-district',
		component: NewDistrictSwitcher,
		protected: false,
	},
	{
		link: '/flipcharts',
		component: Flipchart,
	},
	{
		link: '/flipcharts/:id/print',
		component: Print,
		parentPath: '/flipcharts',
	},
	{
		link: '/flipcharts/:id',
		component: FlipchartDetails,
		parentPath: '/flipcharts',
	},
	{
		link: '/flipcharts/view/:id/',
		component: props => <FlipchartDetails {...props} viewMode />,
		parentPath: '/flipcharts',
	},
	{
		link: '/binders',
		component: BindersDocuments,
	},
	{
		link: '/binders/edit/:id/',
		component: BinderDetails,
		parentPath: '/binders',
	},
	{
		link: '/binders/view/:id/',
		component: props => <BinderDetails {...props} viewMode />,
		parentPath: '/binders',
	},
	{
		link: '/drills/scenarios/:id',
		component: ScenarioDetails,
		parentPath: '/drills',
	},
	{
		link: '/drillsV2/scenarios/:id',
		component: ScenarioDetailsV2,
		parentPath: '/drillsV2',
	},
	{
		link: '/drillsV2/reminders',
		component: ManageReminders,
		parentPath: '/drillsV2',
	},
	{
		link: '/maps',
		component: Maps,
	},
	{
		link: '/maps/edit/:siteid/:floorplanId?',
		component: ({ location, ...props }) => <MapView {...props} viewMode={false} />,
		parentPath: '/maps',
	},
	{
		link: '/maps/view/:siteid/:floorplanId?',
		component: ({ location, ...props }) => <MapView {...props} viewMode />,
		parentPath: '/maps',
	},
	{
		link: '/maps/export/:siteId/:floorplanId',
		component: MapExport,
	},
	{
		link: '/maps/view/:siteid/:floorplanId?/:xToken?',
		component: ({ location, ...props }) => <MapView {...props} viewMode />,
		protected: false,
		parentPath: '/maps',
	},
	{
		link: '/core/templates/:id',
		component: CoreTemplateDetails,
	},
	{
		link: '/core/view/:id',
		component: props => <CoreTemplateDetails {...props} viewMode />,
	},
	{
		link: '/drillsV2',
		component: props => <DrillsV2 {...props} tabId="dashboard" />,
	},
	{
		link: '/drillsV2/scenarios',
		component: props => <DrillsV2 {...props} tabId="scenarios" />,
		parentPath: '/drillsV2',
	},
	{
		link: '/drillsV2/statistics',
		component: props => <DrillsV2 {...props} tabId="statistics" />,
		parentPath: '/drillsV2',
	},
	{
		link: '/drillsV2/settings',
		component: DrillsSettings,
		parentPath: '/drillsV2',
	},
	{
		link: '/drillsV2/settings/manageDistrictDrillRequirements',
		component: ManageDDRequirementsV2,
		parentPath: '/drillsV2',
	},
	{
		link: '/drillsV2/settings/manageFirstResponderNotifications',
		component: ManageFirstResponderNotifications,
		parentPath: '/drillsV2',
	},
	{
		link: '/drillsV2/admin',
		component: DrillLogsAdmin,
		parentPath: '/drillsV2',
	},
	{
		link: '/drillsV2/admin/fields',
		component: DrillsFieldsList,
		parentPath: '/drillsV2',
	},
	{
		link: '/drillsV2/admin/types',
		component: DrillTypes,
		parentPath: '/drillsV2',
	},
	{
		link: '/drillsV2/admin/requirements',
		component: ManageStateDrillRequirements,
		parentPath: '/drillsV2',
	},
	{
		link: '/safety-plans/:id',
		component: DistrictTemplateDetails,
		parentPath: '/safety-plans',
	},
	{
		link: '/drillsV2/log/:year/:id',
		component: DrillLog,
		parentPath: '/drillsV2',
	},
	{
		link: '/safety-plans/view/:id',
		component: props => <DistrictTemplateDetails {...props} viewMode />,
		parentPath: '/safety-plans',
	},

	{
		link: '/safety-plans/:id/export',
		component: props => <SafetyPlansDistrictExport {...props} viewMode />,
		shouldRevalidate: () => false,
	},
	{
		link: '/user-management',
		component: UsersCheckSite,
	},
	{
		link: '/users/view/:id',
		component: AccountManager,
		parentPath: '/user-management',
	},
	{
		link: '/users/myprofile',
		component: AccountManager,
		parentPath: '/user-management',
	},
	{
		link: '/users/view/:id',
		component: ModuleAccount,
		parentPath: '/user-management',
	},
	{
		link: '/call-list',
		component: CallList,
	},
	{
		link: CALLLIST_ROUTES.LISTINGV2,
		component: CallListV2,
	},
	{
		link: '/first-responders',
		component: FirstResponders,
		parentPath: '/user-management',
	},
	{
		link: '/first-responders/view/:id',
		component: FirstResponderView,
		parentPath: '/user-management',
	},
	{
		link: '/user-roles',
		component: UserRoles,
		parentPath: '/user-management',
	},
	{
		link: '/contacts/:id',
		component: Contacts,
		parentPath: '/call-list',
	},
	{
		link: CALLLIST_ROUTES.CONTACTSV2 + ':id',
		component: ContactsV2,
		parentPath: CALLLIST_ROUTES.LISTINGV2,
	},
	{
		link: '/components-v2',
		component: ComponentsV2,
	},
	{
		link: '/rapid-alarms',
		component: RapidAlarms,
	},
	{
		link: '/rapid-alarms/edit/:id',
		component: RapidAlarmDetails,
		parentPath: '/rapid-alarms',
	},
	{
		link: '/rapid-alarms/settings',
		component: AlarmSettings,
		parentPath: '/rapid-alarms',
	},
	{
		link: '/rapid-alarms/integrations/edit',
		component: EditIntegrations,
		parentPath: '/rapid-alarms',
	},
	{
		link: '/rapid-alarms/integrations/test-connection',
		component: TestConection,
		parentPath: '/rapid-alarms',
	},
	{
		link: '/safety-plansV1',
		component: Dashboard,
	},
	{
		link: '/rapid-alarms/settings/create-alarm-type',
		component: CreateAlarmType,
		parentPath: '/rapid-alarms',
	},
	{
		link: '/rapid-alarms/settings/alarm-types/:id',
		component: ViewAlarmType,
		parentPath: '/rapid-alarms',
	},
	{
		link: '/past-alarms/:id',
		component: PastAlarmDetails,
		parentPath: '/',
	},
	{
		link: '/active-alarms/:id',
		component: ActiveAlarmDetails,
		parentPath: '/',
	},
	{
		link: '/active-alarms/roll-call-reunification/:id',
		component: RollCallDetails,
		parentPath: '/',
	},
];
