import React from 'react';
import { BootstrapedTable, sortingStyle, TableCell, LoadingSpinner, Button } from 'componentsV2';
import PropTypes from 'prop-types';
import { ActionsList } from './ActionsList';
import { RAPID_ICONS } from 'assets/respond';
import { DurationAlarm } from './DurationAlarm';
import { TRIGGER_ALARM_TYPES } from '../../../consts';
import './css/PastAlarmsTable.scss';
import { formatDate } from '../../../../../utilities/dates';
import { DateTime } from 'luxon';

export const PastAlarmsTable = ({
	list,
	loading,
	totalRows,
	filterationData,
	setFilterationData,
	history,
	showPagination = true,
	enableSorting = true,
	showHeader = false,
}) => {
	const defaultSorted = [{ dataField: 'formattedDateStart', order: 'desc' }];

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'disabled',
			dataField: 'disabled',
			hidden: true,
		},
		{
			key: 'building',
			dataField: 'building',
			text: 'Site',
			headerStyle: { width: '16%' },
			sort: enableSorting,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => <TableCell text={value?.name} />,
		},
		{
			key: 'alarmType',
			dataField: 'alarmType',
			text: 'Alarm type',
			sort: enableSorting,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (
				<TableCell
					text={value?.title}
					supportText={
						row.mode ? row.mode.charAt(0).toUpperCase() + row.mode?.slice(1) : ''
					}
					leftIcon={<img src={RAPID_ICONS[(value?.icon)]} />}
				/>
			),
		},
		{
			key: 'duration',
			dataField: 'duration',
			text: 'Duration',
			sort: enableSorting,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => <DurationAlarm row={row} value={value} twoLines={true} />,
		},
		{
			key: 'formattedDateStart',
			dataField: 'dateStarted',
			text: 'Start date',
			sort: enableSorting,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (
				<TableCell
					text={formatDate(value?.date, DateTime.TIME_SIMPLE)}
					supportText={row.formattedDateStart}
				/>
			),
		},
		{
			key: 'initiatedBy',
			dataField: 'initiatedBy',
			text: 'Initiated by',
			sort: enableSorting,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) =>
				row.triggeredVia == '911_cellular' ? TRIGGER_ALARM_TYPES.UNKNOWN : value,
		},
		{
			key: 'triggeredVia',
			dataField: 'triggeredVia',
			text: 'Triggered via',
			sort: enableSorting,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) =>
				row.triggeredVia == '911_cellular'
					? TRIGGER_ALARM_TYPES.NineOneOneCellular
					: value?.charAt(0).toUpperCase() + value?.slice(1),
		},
		{
			key: 'actions',
			dataField: 'id',
			text: '',
			classes: 'no-padding',
			formatter: (value, row) => <ActionsList alarm={row} history={history} />,
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = 'name';
			switch (sortField) {
				case 'dateStarted':
					sortColumn = 'startDate';
					break;
				default:
					sortColumn = sortField;
					break;
			}
			if (sortColumn != filterationData.sortKey || sortOrder != filterationData.sortDir) {
				setFilterationData(oldData => ({
					...oldData,
					sortKey: sortColumn,
					sortDir: sortOrder,
				}));
			}
		}
	};

	const setRowsPerPage = value => {
		setFilterationData(oldData => ({ ...oldData, perPage: value }));
	};

	const setCurrentPage = value => {
		setFilterationData(oldData => ({ ...oldData, page: value }));
	};

	if (loading) return <LoadingSpinner className="mt-3" />;
	return (
		<div className={`past-alarms-table-container ${!showPagination ? 'no-pagination' : ''}`}>
			{showHeader && (
				<div className="past-alarms-table-header">
					<h4>Past Alarms</h4>
					<Button
						text="See all"
						rightIcon="arrow-right"
						type="ghostPrimary"
						size="md"
						onClick={() => {
							history.push('/rapid-alarms?type=past');
						}}
					/>
				</div>
			)}
			<BootstrapedTable
				keyField="id"
				data={list}
				columns={columns}
				totalRows={totalRows}
				onTableChange={onTableChange}
				currentPage={filterationData.page}
				rowsPerPage={filterationData.perPage}
				setCurrentPage={setCurrentPage}
				setRowsPerPage={setRowsPerPage}
				defaultSorted={defaultSorted}
				showPagination={showPagination}
			/>
		</div>
	);
};

PastAlarmsTable.propTypes = {
	list: PropTypes.array.isRequired,
};
