import React from 'react';
import './LightTable.css';

const LightTableComponent = ({ headers, data, renders, hiddenIndex = []  }) => {
    return (
        <table className='lightTable'>
        <thead>
            <tr>
                {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                ))}
            </tr>
        </thead>
        <tbody>
            {data.map((item, rowIndex) => (
                <tr key={rowIndex}>
                    { Object.entries(item).map(([key, value], cellIndex) => {
                        return !hiddenIndex.includes(cellIndex) ?
                        (
                            <td key={cellIndex}>
                                { renders[key] ? renders[key](value, item) : value }
                            </td>
                        ) : null
                    })}
                </tr>
            ))}
        </tbody>
        </table>
    );
};

export default LightTableComponent;