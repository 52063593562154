import { HttpService, buildRoute } from 'networking/HttpService';

/** This service is for when we are managing first responder agencies from any db that is NOT 5304 */
export const FirstRespondersService = {
	async getAll(params = {}) {
		const urlSearchParams = new URLSearchParams();
		var arrayValues = '';
		for (const [key, value] of Object.entries(params)) {
			if (key != 'selectedSites') {
				if (Array.isArray(value)) {
					value.map(val => {
						arrayValues += `&${key}[]=${val}`;
					});
				} else {
					urlSearchParams.append(key, value);
				}
			}
		}

		var queryParams = urlSearchParams.toString();
		if (queryParams !== '') {
			queryParams += arrayValues;
		} else {
			queryParams = arrayValues;
		}

		return HttpService({
			method: 'GET',
			route: `/responders?${queryParams}`,
		});
	},

	async getById(params) {
		return await HttpService({
			method: 'GET',
			route: `/responders/${params.id}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	/** Please leave the email encoded. The server automatically decodes the url, so it will always replace the '+' with a space if it's not encoded.
	 * @param {object} formData
	 */
	getByEmail: async formData =>
		HttpService({
			method: 'GET',
			route: `/responders/contact/get-by-email?email=${encodeURIComponent(formData.email)}`,
		}),

	async delete(id, sitesIds) {
		return HttpService({
			method: 'DELETE',
			route: `/responders/${id}`,
			body: {
				sites_ids: sitesIds,
			},
		}).catch(err => {
			throw err;
		});
	},

	async fetchAgencies(zipCode, miles) {
		var raduis = miles['value'];
		return await HttpService({
			method: 'GET',
			route: `/search-agencies/${zipCode}/${raduis}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	async addResponder(formData, FirstResponsderSites) {
		var sitesIds = [];
		for (var key in FirstResponsderSites) {
			if (FirstResponsderSites[key]['value'] !== '*') {
				sitesIds.push(FirstResponsderSites[key]['value']);
			}
		}

		const requestBody = {
			sites_ids: sitesIds,
			source_team_id: parseInt(formData.team_id),
			contact_first_name: formData.first_name,
			contact_last_name: formData.last_name,
			first_responder_name: formData.agency,
			first_responder_address: formData.address,
			first_responder_city: formData.city,
			first_responder_state: formData.state,
			first_responder_zip: parseInt(formData.zip_code),
			contact_email: formData.email,
			lat: formData.lat,
			lon: formData.lon,
		};
		if (formData.google_ref && formData.google_ref != '') {
			requestBody.google_ref = formData.google_ref;
		}
		if (formData.mobile_phone != '') {
			requestBody.contact_mobile_phone = parseInt(formData.mobile_phone);
		}
		if (formData.work_phone != '') {
			requestBody.contact_work_phone = parseInt(formData.work_phone);
		}
		if (formData.home_phone != '') {
			requestBody.contact_home_phone = parseInt(formData.home_phone);
		}
		if (formData.extension != '') {
			requestBody.contact_work_phone_ext = parseInt(formData.extension);
		}

		try {
			return await HttpService({
				method: 'POST',
				route: '/responders',
				body: requestBody,
			});
		} catch (error) {
			throw error;
		}
	},
};
