import React, { useEffect, useState, useContext } from 'react';
import { LoadingSpinner, Accordion, noResult } from 'componentsV2';
import { FloorplanService } from 'services';
import { ContentSearch } from '../SidebarComponents';
import {
	DocumentsBindersContent,
	ImagesContent,
	PanoramasContent,
	NotesContent,
	CameraLinkContent,
	VideosContent,
	NoShapeContentState,
} from './ShapeContentItemsList';
import './css/ShapeContent.scss';
import { MapsContext, MapsProvider } from '../../../Providers';

export const ShapeContent = ({
	siteId,
	floorplan,
	selectedShape,
	shapeId = null,
	editPermission,
	isEditMode,
	viewPermissionOnSiteId,
}) => {
	const [loading, setLoading] = useState(false);
	const [contentTabs, setContentTabs] = useState([]);
	const [filteredImages, setFilteredImages] = useState([]);
	const [filteredVideos, setFilteredVideos] = useState([]);
	const [filteredDocumentsBinders, setFilteredDocumentsBinders] = useState([]);
	const [filteredPanoramas, setFilteredPanoramas] = useState([]);
	const [filteredNotes, setFilteredNotes] = useState([]);
	const [filteredLinks, setFilteredLinks] = useState([]);
	const [shapeType, setShapeType] = useState(null);
	const [isSearchedContent, setIsSearchedContent] = useState(false);
	const MapCTX = useContext(MapsContext);
	const floorplanArchived = !!floorplan?.archivedBy;
	const parsedShapeId = shapeId === null ? parseInt(selectedShape?.id) : parseInt(shapeId);

	useEffect(() => {
		loadContentData();
	}, [parsedShapeId]);

	useEffect(() => {
		loadContentData();
	}, [isEditMode]);

	const loadContentData = () => {
		setLoading(true);

		FloorplanService.getAreaContentDetails({
			site_id: siteId,
			shape_id: parsedShapeId,
			floorplan_id: floorplan.id,
		})
			.then(response => {
				if (response.statusCode === 200) {
					setShapeType(response.data.shapeType);
					setFilteredPanoramas(response.data.panoramas);
					setFilteredNotes(response.data.notes);
					setFilteredLinks(response.data.links);
					setFilteredVideos(
						response.data.documentsBinders.filter(obj => obj.type == 'video'),
					);
					setFilteredImages(
						response.data.documentsBinders.filter(obj => obj.type == 'image'),
					);
					setFilteredDocumentsBinders(
						response.data.documentsBinders.filter(obj =>
							['document', 'binder'].includes(obj.type),
						),
					);
				}
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (
			filteredPanoramas.length == 0 &&
			filteredVideos.length == 0 &&
			filteredImages.length == 0 &&
			filteredNotes.length == 0 &&
			filteredDocumentsBinders.length == 0 &&
			filteredLinks.length == 0
		) {
			setContentTabs([]);
		} else {
			let contentTabsData = [];
			contentTabsData = [
				{
					id: 0,
					title: 'Documents / Binders',
					isOpenContent: filteredDocumentsBinders.length > 0 && isSearchedContent,
					content: (
						<DocumentsBindersContent
							documentsBinders={filteredDocumentsBinders}
							contentType="document"
							editPermission={editPermission}
							floorplanArchived={floorplanArchived}
							isSearchedContent={isSearchedContent}
							shapeType={shapeType}
							siteId={siteId}
							shapeId={parsedShapeId}
							loadContentData={loadContentData}
							isEditMode={isEditMode}
							viewPermissionOnSiteId={viewPermissionOnSiteId}
							floorplan={floorplan}
						/>
					),
				},
				{
					id: 1,
					title: 'Images',
					isOpenContent: filteredImages.length > 0 && isSearchedContent,
					content: (
						<ImagesContent
							images={filteredImages}
							contentType="image"
							editPermission={editPermission}
							floorplanArchived={floorplanArchived}
							isSearchedContent={isSearchedContent}
							shapeType={shapeType}
							siteId={siteId}
							shapeId={parsedShapeId}
							loadContentData={loadContentData}
							isEditMode={isEditMode}
							viewPermissionOnSiteId={viewPermissionOnSiteId}
							floorplan={floorplan}
						/>
					),
				},
				{
					id: 2,
					title: '360 images',
					isOpenContent: filteredPanoramas.length > 0 && isSearchedContent,
					content: (
						<PanoramasContent
							panoramas={filteredPanoramas}
							contentType="360image"
							editPermission={editPermission}
							floorplanArchived={floorplanArchived}
							isSearchedContent={isSearchedContent}
							shapeType={shapeType}
							siteId={siteId}
							shapeId={parsedShapeId}
							loadContentData={loadContentData}
							isEditMode={isEditMode}
							viewPermissionOnSiteId={viewPermissionOnSiteId}
							floorplan={floorplan}
						/>
					),
				},
				{
					id: 3,
					title: 'Videos',
					isOpenContent: filteredVideos.length > 0 && isSearchedContent,
					content: (
						<VideosContent
							videos={filteredVideos}
							contentType="video"
							editPermission={editPermission}
							floorplanArchived={floorplanArchived}
							isSearchedContent={isSearchedContent}
							shapeType={shapeType}
							siteId={siteId}
							shapeId={parsedShapeId}
							loadContentData={loadContentData}
							isEditMode={isEditMode}
							viewPermissionOnSiteId={viewPermissionOnSiteId}
							floorplan={floorplan}
						/>
					),
				},
				{
					id: 4,
					title: 'Notes',
					isOpenContent: filteredNotes.length > 0 && isSearchedContent,
					content: (
						<NotesContent
							notes={filteredNotes}
							contentType="notes"
							editPermission={editPermission}
							floorplanArchived={floorplanArchived}
							isSearchedContent={isSearchedContent}
							selectedShape={selectedShape}
							shapeType={shapeType}
							siteId={siteId}
							shapeId={parsedShapeId}
							loadContentData={loadContentData}
							isEditMode={isEditMode}
							viewPermissionOnSiteId={viewPermissionOnSiteId}
							floorplan={floorplan}
						/>
					),
				},
			];
			if (shapeType == 'icon' && selectedShape.icon.type == 'Camera') {
				contentTabsData.push({
					id: 5,
					title: 'Camera',
					isOpenContent: false,
					content: (
						<CameraLinkContent
							links={filteredLinks}
							contentType="links"
							editPermission={editPermission}
							floorplanArchived={floorplanArchived}
							isSearchedContent={isSearchedContent}
							shapeType={shapeType}
							siteId={siteId}
							shapeId={parsedShapeId}
							selectedShape={selectedShape}
							loadContentData={loadContentData}
							isEditMode={isEditMode}
							viewPermissionOnSiteId={viewPermissionOnSiteId}
							floorplan={floorplan}
						/>
					),
				});
			}
			setContentTabs(contentTabsData);
		}
	}, [
		filteredPanoramas,
		filteredVideos,
		filteredImages,
		filteredNotes,
		filteredDocumentsBinders,
		filteredLinks,
		isSearchedContent,
		shapeType,
	]);

	const onSearchHandler = title => {
		const searchTitle = title ? title.toLowerCase() : '';
		setIsSearchedContent(!!title);
		setFilteredPanoramas(
			filteredPanoramas.filter(item => item.contentTitle.toLowerCase().includes(searchTitle)),
		);
		setFilteredVideos(
			filteredVideos.filter(item => item.contentTitle.toLowerCase().includes(searchTitle)),
		);
		setFilteredImages(
			filteredImages.filter(item => item.contentTitle.toLowerCase().includes(searchTitle)),
		);
		setFilteredNotes(
			filteredNotes.filter(item => item.contentTitle.toLowerCase().includes(searchTitle)),
		);
		setFilteredDocumentsBinders(
			filteredDocumentsBinders.filter(item =>
				item.contentTitle.toLowerCase().includes(searchTitle),
			),
		);
	};

	return (
		<>
			<MapsProvider reloadContent={loadContentData}>
				{loading && <LoadingSpinner />}
				{!loading && (
					<div className="area-content-container">
						<div className="area-content-label">Content</div>
						<ContentSearch
							editPermission={editPermission}
							onSearch={onSearchHandler}
							onAddClickHandler={() => {
								MapCTX.showAddContentsModal(
									selectedShape,
									siteId,
									floorplan.id,
									filteredLinks,
									loadContentData,
								);
							}}
							addBtnTitle="Add Content"
							showAddBtn={editPermission && !floorplanArchived && isEditMode}
						/>
						{contentTabs.length > 0 ? (
							<Accordion items={contentTabs} />
						) : (
							<NoShapeContentState
								icon={noResult}
								header={
									isSearchedContent
										? 'No content found'
										: 'No content currently added.'
								}
								description={
									isSearchedContent
										? 'Your search didn’t match any content. Please try again.'
										: editPermission && !floorplanArchived && isEditMode
										? 'Click the + button to add a content.'
										: ''
								}
							/>
						)}
					</div>
				)}
			</MapsProvider>
		</>
	);
};
