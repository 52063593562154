import React, { useState, useEffect, useContext } from 'react';
import {
	LoadingSpinner,
	Breadcrumb,
	CardContainer,
	Input,
	TextArea,
	Button,
	FeaturedIcon,
	ToasterContext,
	Intent,
} from 'componentsV2';
import './DrillLog.scss';
import { RadioButtonGroup } from 'componentsV2/RadioButton';
import { Radio } from '@blueprintjs/core';
import {
	DrillsService,
	DrillStateRequirementsService,
	DrillDistrictRequirementsService,
	DrillLogService,
} from 'services';
import { formatDate } from 'utilities/dates';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import { formatScheduledDate, getStateId } from './utils';

export const FIELD_OPTIONS = [
	{ label: 'Text', value: 'text' },
	{ label: 'Text Multi-Line', value: 'textarea' },
	{ label: 'Number', value: 'number' },
	{ label: 'Date', value: 'date' },
	{ label: 'Date/Time', value: 'datetime' },
	{ label: 'Yes/No', value: 'yesno' },
	{ label: 'Satisfactory/Needs Improvement', value: 'satisfactory' },
];

export const DrillLog = ({ match, history }) => {
	const [drillInfo, setDrillInfo] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [requiredFields, setRequiredFields] = useState([]);
	const [scheduledDrillDate, setScheduledDrillDate] = useState('');
	const [logDrillDate, setLogDrillDate] = useState('');
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const permissions = useSelector(state => state.route.permissions);
	const logPermission = checkPermission('drilllogs_log', permissions);

	// Form validation
	useEffect(() => {
		setIsSubmitDisabled(
			requiredFields.some(field => field.value === '') || logDrillDate === '',
		);
	}, [requiredFields, logDrillDate]);

	// Jon Kline - this function is ridiculous, but I couldn't come up with a more concise way of getting requirements based on the current backend
	const getRequiredFields = async () => {
		const drill = await DrillsService.getScheduledDrill(match.params.id);
		const allDistrictDrillRequirements = await DrillDistrictRequirementsService.getAllRequirements(
			drill.year,
			getStateId(),
		);

		const drillTypeRequirements = allDistrictDrillRequirements.find(
			requirement => requirement.uuid === drill.drillType.uuid,
		);

		const drillRequirementData = [];
		let fields = [];
		if (
			drillTypeRequirements?.districtRequirement?.fieldUuids.length > 0 &&
			drillTypeRequirements?.stateRequirement?.fieldUuids.length > 0
		) {
			const districtRequirements = await DrillDistrictRequirementsService.fetchRequiredFields(
				drillTypeRequirements?.districtRequirement?.id,
			);
			const stateRequirements = await DrillStateRequirementsService.fetchRequiredFields(
				drillTypeRequirements?.stateRequirement?.id,
			);
			fields = [
				...new Map(
					[...districtRequirements, ...stateRequirements].map(item => [item.uuid, item]),
				).values(),
			];
		} else if (drillTypeRequirements?.districtRequirement?.fieldUuids.length > 0) {
			fields = await DrillDistrictRequirementsService.fetchRequiredFields(
				drillTypeRequirements?.districtRequirement?.id,
			);
		} else if (drillTypeRequirements?.stateRequirement?.fieldUuids.length > 0) {
			fields = await DrillStateRequirementsService.fetchRequiredFields(
				drillTypeRequirements?.stateRequirement?.id,
			);
		}

		setDrillInfo(drill);

		fields.forEach((item, index) => {
			drillRequirementData[item] = index;
		});

		const sortedFields = fields?.sort(
			(a, b) => drillRequirementData[a.uuid] - drillRequirementData[b.uuid],
		);

		// init input values on requiredFields objects
		let initInputValues = [];
		if (drill.log) {
			// answers = [{ id: 0, fieldUuid: '', value: '' }, ]
			const { answers } = drill.log;
			const accountedFields = answers.map(answer => answer.fieldUuid);
			initInputValues = sortedFields.map(field => {
				// if field is answered, set that answer as the field value, else set as empty string
				if (accountedFields.includes(field.uuid)) {
					const { value } = answers.find(answer => answer.fieldUuid === field.uuid);
					return { ...field, value };
				}

				if (field.type === 'Yes/No' || field.type === 'yesno') {
					return { ...field, value: 'Yes' };
				}

				return { ...field, value: '' };
			});

			if (drill.log.logCompleted) {
				// we need to display the actual selected date and time i.e.drillLoggedDate
				setLogDrillDate(formatScheduledDate(drill.log.drillLoggedDate.date));
			}
		} else {
			initInputValues = sortedFields.map(field => {
				if (field.type === 'Yes/No' || field.type === 'yesno') {
					return { ...field, value: 'Yes' };
				}
				return { ...field, value: '' };
			});
		}
		setRequiredFields(initInputValues);
		setScheduledDrillDate(formatScheduledDate(formatDate(drill.scheduledDrillDateISO)));
		setIsLoading(false);
	};

	useEffect(() => {
		getRequiredFields().catch(console.error);
	}, []);

	const breadCrumbs = [
		{ label: 'Drills', url: '/drillsV2' },
		{ label: 'Dashboard', url: '/drillsV2' },
		{ label: 'Log drill' },
	];

	const handleInputChange = (fieldUUID, event) => {
		setRequiredFields(
			requiredFields.map(field => {
				if (field.uuid === fieldUUID) {
					return { ...field, value: event.target.value };
				}
				return field;
			}),
		);
	};

	const getColumnSpan = fieldType =>
		fieldType === 'textarea' || fieldType === 'Yes/No' || fieldType === 'yesno'
			? 'full'
			: 'half';

	const renderField = field => {
		switch (field.type) {
			case 'text':
			case 'number':
			case 'date':
				return (
					<Input
						type={field.type}
						label={field.name}
						value={field.value}
						isRequired
						onChange={event => handleInputChange(field.uuid, event)}
						disabled={!logPermission}
					/>
				);
			case 'datetime':
				return (
					<Input
						type="datetime-local"
						label={field.name}
						value={field.value}
						isRequired
						onChange={event => handleInputChange(field.uuid, event)}
						disabled={!logPermission}
					/>
				);
			case 'satisfactory':
				return (
					<Input
						type="text"
						label={field.name}
						value={field.value}
						isRequired
						onChange={event => handleInputChange(field.uuid, event)}
						disabled={!logPermission}
					/>
				);
			case 'datetime':
				return (
					<Input
						type="datetime-local"
						label={field.name}
						value={field.value}
						isRequired={true}
						onChange={event => handleInputChange(field.uuid, event, field.type)}
						disabled={!logPermission}
					/>
				);
			case 'satisfactory':
				return (
					<Input
						type="text"
						label={field.name}
						value={field.value}
						isRequired={true}
						onChange={event => handleInputChange(field.uuid, event, field.type)}
						disabled={!logPermission}
					/>
				);
			case 'textarea':
				return (
					<TextArea
						label={field.name}
						value={field.value}
						isRequired
						onChange={event => handleInputChange(field.uuid, event)}
						disabled={!logPermission}
					/>
				);
			case 'Yes/No':
			case 'yesno':
				return (
					<RadioButtonGroup
						label={field.name}
						onChange={event => handleInputChange(field.uuid, event)}
						required
						selectedValue={field.value}
						disabled={!logPermission}
					>
						<Radio label="Yes" value="Yes" />
						<Radio label="No" value="No" />
					</RadioButtonGroup>
				);
			default:
				return null;
		}
	};

	const handleLogDrillDateChange = event => {
		setLogDrillDate(event.target.value);
	};

	const toaster = useContext(ToasterContext);

	const handleLogDrill = async () => {
		setIsLoading(true);
		try {
			// create drill log if doesn't exist
			if (!drillInfo.log) {
				await DrillLogService.createDrillLog(drillInfo.scheduleId);
			}
			// post drill log
			// properties: [{ fieldUuid: '', value: '' }, ]
			const properties = requiredFields.map(field => ({
				fieldUuid: field.uuid,
				value: field.value,
			}));
			await DrillLogService.postDrillLog(
				drillInfo.scheduleId,
				drillInfo.scheduledDrillDateISO,
				logDrillDate,
				properties,
			);
			toaster(
				`You have successfully logged "${drillInfo.drillType.name}".`,
				Intent.SUCCESS,
				<FeaturedIcon icon="time" type="Success" />,
			);
			history.push('/drillsV2');
			setIsLoading(false);
		} catch (error) {
			toaster(
				`Failed to log "${drillInfo.drillType.name}".`,
				Intent.DANGER,
				<FeaturedIcon icon="error" type="Error" />,
			);
			history.push('/drillsV2');
			console.log(error);
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return <LoadingSpinner />;
	}

	return (
		<>
			<div className="drill-log-page">
				<div className="drill-log-header">
					<Breadcrumb items={breadCrumbs} />
					<h3>Log "{drillInfo.drillType.name}"</h3>
				</div>
				<CardContainer
					cardCustomClass="drilllog-form-container"
					bodyCustomClass="drilllog-form-body"
				>
					<div className="field-container column-half">
						<Input
							type="datetime-local"
							label="Drill scheduled date"
							isRequired
							disabled
							value={scheduledDrillDate}
						/>
					</div>
					<div className="field-container column-half">
						<Input
							type="datetime-local"
							label="Actual drill date and time"
							isRequired
							value={logDrillDate}
							onChange={handleLogDrillDateChange}
							disabled={!logPermission}
						/>
					</div>
					{requiredFields.map((field, index) => {
						const columnSpan = getColumnSpan(field.type);
						return (
							<div
								key={field.uuid}
								className={`field-container ${
									columnSpan === 'full' ? 'column-full' : 'column-half'
								}`}
							>
								{renderField(field)}
							</div>
						);
					})}
				</CardContainer>
				<div className="footer">
					<Button
						type="tertiary"
						text="Cancel"
						onClick={() => history.push('/drillsV2')}
					/>
					<Button
						type="primaryDefault"
						text="Log Drill"
						onClick={handleLogDrill}
						disabled={isSubmitDisabled || !logPermission}
					/>
				</div>
			</div>
		</>
	);
};
