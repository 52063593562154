import React, { useEffect, useState, useRef, useMemo } from 'react';
import { FloorplanService } from 'services/FloorplanService';
import Konva from 'konva';
import { drawShapes, drawFloorPlanImage } from './CanvasDrawFn';
import './css/MapExport.scss';
import { Helper } from 'utilities/helper';

// Wrap the capture content in a container div if jpeg download
// Leave as-is if pdf download
const CaptureTypeFormatter = ({ children, exportType }) => {
	return exportType === 'jpeg' ? (
		<div className="map-export-capture-jpeg">{children}</div>
	) : (
		<>{children}</>
	);
};

// Component for details section item
const DetailsItem = ({ label, children }) => {
	return (
		<div className="details-item">
			<p className="details-item-label">{label}</p>
			<div>{children}</div>
		</div>
	);
};

// Component for icons section item
const IconItem = ({ title, url }) => {
	return (
		<div className="icon-item">
			<div className="img-bg">
				<img src={url} />
			</div>
			<span>{title}</span>
		</div>
	);
};

// Component for legends section item
const LegendItem = ({ title, hex }) => {
	return (
		<div className="legend-item">
			<div className="legend-color" style={{ backgroundColor: `#${hex}` }} />
			<span>{title}</span>
		</div>
	);
};

export const MapExport = ({ match }) => {
	const siteId = match.params.siteId;
	const floorplanId = match.params.floorplanId;
	const exportType = new URLSearchParams(window.location.search).get('type');

	const [floorplanDetails, setFloorplanDetails] = useState(null);
	const [icons, setIcons] = useState(null);
	const [legends, setLegends] = useState(null);
	const canvasContainer = useRef(null);
	const stage = useRef(null);
	const layer = useRef(null);
	const [isRendered, setIsRendered] = useState(false);
	const titleRef = useRef(null);
	const detailsRef = useRef(null);
	const legendsRef = useRef(null);
	const iconsRef = useRef(null);
	const pageRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);

	// Get floorplan data
	useEffect(() => {
		const fetchFloorplanDetails = async () => {
			const floorplanData = await FloorplanService.getFloorplansBySiteId({
				siteId,
				floorplanId,
			});
			setFloorplanDetails(floorplanData?.data[0]);
		};

		const fetchIconList = async () => {
			const iconListData = await FloorplanService.getIconList(siteId);
			const iconList = iconListData?.data.filter(
				(icon) => icon.floorplanId === Number(floorplanId),
			);
			setIcons(iconList);
		};

		const fetchLegendList = async () => {
			const legendList = await FloorplanService.getAllLegends();
			setLegends(legendList?.data);
		};

		fetchFloorplanDetails().catch((err) => console.log(err));
		fetchIconList().catch((err) => console.log(err));
		fetchLegendList().catch((err) => console.log(err));
	}, []);

	// Render the map using Konva
	useEffect(async () => {
		if (floorplanDetails) {
			stage.current = new Konva.Stage({
				container: canvasContainer.current,
				width: 3840,
				height: 2160,
				x: 0,
				y: 0,
				position: 'relative',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
			});

			layer.current = new Konva.Layer();
			stage.current.add(layer.current);
			stage.current.batchDraw();

			const image = floorplanDetails.link;
			let FloorplanImg;
			if (image) {
				FloorplanImg = await drawFloorPlanImage(
					layer.current,
					image,
					floorplanDetails,
					null,
				);

				layer.current.add(FloorplanImg);

				// Calculate scale to fit the image into the stage
				const scaleX = stage.current.width() / FloorplanImg.width();
				const scaleY = stage.current.height() / FloorplanImg.height();
				const scale = Math.min(scaleX, scaleY); // Choose the smallest scale factor

				// Apply the scaling to the stage.current
				stage.current.scale({ x: scale, y: scale });

				// Move the stage to correct position relative to the floorplan image
				stage.current.position({
					//x: Math.abs(FloorplanImg.x()),
					//y: Math.abs(FloorplanImg.y()),
					x: -1 * FloorplanImg.x(),
					y: -1 * FloorplanImg.y(),
				});

				drawShapes(
					null,
					null,
					layer.current,
					floorplanDetails.shapes,
					floorplanDetails,
					null,
					null,
				);

				layer.current.draw();
			}
		}
	}, [floorplanDetails]);

	const getTotalElementHeight = (element) => {
		const style = window.getComputedStyle(element);
		const marginTop = parseFloat(style.marginTop);
		const marginBottom = parseFloat(style.marginBottom);
		const calculatedHeight = element.offsetHeight + marginTop + marginBottom;
		return calculatedHeight;
	};

	// Show the waitForSelector element once everything is rendered
	useEffect(() => {
		if (floorplanDetails && icons && legends) {
			setIsLoaded(true);
		}
	}, [floorplanDetails, icons, legends]);

	// Scale floorplan based on available space
	const canvasContainerStyle = useMemo(() => {
		if (isLoaded) {
			// Calculate free space
			const pageHeight = getTotalElementHeight(pageRef.current);
			const titleHeight = getTotalElementHeight(titleRef.current);
			const detailsHeight = getTotalElementHeight(detailsRef.current);
			const legendsHeight = getTotalElementHeight(legendsRef.current);
			const iconsHeight = getTotalElementHeight(iconsRef.current);

			const freeSpace =
				pageHeight - (titleHeight + detailsHeight + legendsHeight + iconsHeight);

			return {
				marginBottom: `${-1 * (2160 * (1 - freeSpace / 2160))}px`,
				transform: `scale(${freeSpace / 2160})`,
			};
		} else {
			return {};
		}
	}, [isLoaded]);

	useEffect(() => {
		if (isLoaded) {
			// Remove the accessiBe logo
			const acsbButtonShadowRoot = document.querySelectorAll('access-widget-ui').item(2);
			const acsbButton = acsbButtonShadowRoot.shadowRoot.querySelector('button');
			acsbButton.hidden = true;

			setIsRendered(true);
		}
	}, [canvasContainerStyle]);

	return (
		floorplanDetails && (
			<CaptureTypeFormatter exportType={exportType}>
				<div ref={pageRef} className="map-export-capture-content">
					<h1 ref={titleRef}>{floorplanDetails?.title}</h1>
					<div ref={detailsRef} className="map-export-floorplan-details">
						<DetailsItem label="Site name">
							<p>{floorplanDetails?.building?.name}</p>
						</DetailsItem>
						<DetailsItem label="Site location">
							<p>{floorplanDetails?.building?.address}</p>
							<p>
								{floorplanDetails?.building?.city},{' '}
								{floorplanDetails?.building?.state}{' '}
								{floorplanDetails?.building?.zip},{' '}
								{floorplanDetails?.building?.country}
							</p>
						</DetailsItem>
						<DetailsItem label="Last modified by and date">
							<p>
								{floorplanDetails?.modifiedBy?.first_name}{' '}
								{floorplanDetails?.modifiedBy?.last_name}
							</p>
							<p>{floorplanDetails?.formattedDateModified}</p>
						</DetailsItem>
						<DetailsItem label="Created by and date">
							<p>
								{floorplanDetails?.createdBy?.first_name}{' '}
								{floorplanDetails?.createdBy?.last_name}
							</p>
							<p>{floorplanDetails?.formattedDateCreated}</p>
						</DetailsItem>
						<DetailsItem label="Office phone number">
							<p>
								{Helper.formatDigitsToPhone(
									floorplanDetails?.building?.officePhone,
								)}
							</p>
						</DetailsItem>
						<DetailsItem label="Emergency phone number">
							<p>
								{Helper.formatDigitsToPhone(
									floorplanDetails?.building?.emergencyPhone,
								)}
							</p>
						</DetailsItem>
					</div>
					<div
						className="map-export-canvas-container"
						ref={canvasContainer}
						style={canvasContainerStyle}
					/>
					<div ref={legendsRef} className="map-export-legends-container">
						{legends &&
							legends.map(
								(legend) =>
									legend?.description && (
										<LegendItem title={legend?.description} hex={legend?.hex} />
									),
							)}
					</div>
					<div ref={iconsRef} className="map-export-icons-container">
						{icons &&
							icons.map((icon) => (
								<IconItem title={icon?.title} url={icon?.iconURL} />
							))}
					</div>
					{isRendered && <div className="map-export-wait-for" />}
				</div>
			</CaptureTypeFormatter>
		)
	);
};
