import React from 'react';
import { Switch } from '@blueprintjs/core';
import { SearchBarWithTags } from 'components/SearchBarWithTags';
import AdvancedSearchModal from './AdvancedSearchModal';
import EnableUsersModal from './EnableUsersModal';
import DisableUsersModal from './DisableUsersModal';
import BulkEmailModal from './BulkEmailModal';
import DropdownButton from 'components/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import AssignToSitesModal from './AssignToSitesModal';
import AddUserButton from './AddUserButton';
import 'components/Forms/index.css';
import './css/UserFilters.scss';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { RolesDropdown } from 'components/RolesDropdown';
import { ExportUsersModal } from './ExportUsersModal';
import { flags } from 'utilities';

const UserFilters = ({
	OnSelectSites,
	OnSelectRoles,
	OnSearchUsers,
	OnIncludeDisabled,
	onDeleteTag,
	advancedSearchHandler,
	advancedSearchCancelBtnHandler,
	defaultSelectedSites,
	defaultSelectedRoles,
	defaultIncludeDisabledUsers,
	defaultIncludeDisabledSites,
	defaultIncludeNeverLoggedUsers,
	defaultStaffTitle,
	defaultUserName,
	defaultEmailLogin,
	searchBarTags,
	setModalToShow,
	modalToShow,
	userEditPermission,
	showAddUserBtn,
	selectedRows,
	fetchUsers,
	showTagInput,
	onUpdateUsers,
	onCloseModal,
	rolesList,
	siteList,
	setSelectedRows,
	onClearSearchBar,
}) => {
	const {
		isOn,
		names: { ENV_DEV_ONLY },
	} = flags;

	return (
		<>
			{modalToShow === 'assignToSitesModal' && (
				<AssignToSitesModal
					setModalToShow={setModalToShow}
					selectedUsers={selectedRows}
					onConfirmAction={onUpdateUsers}
					onCloseModal={onCloseModal}
				/>
			)}
			{modalToShow === 'AdvancedSearchModal' && (
				<AdvancedSearchModal
					advancedSearchHandler={advancedSearchHandler}
					advancedSearchCancelBtnHandler={advancedSearchCancelBtnHandler}
					defaultSelectedSites={defaultSelectedSites}
					defaultSelectedRoles={defaultSelectedRoles}
					defaultIncludeDisabledUsers={defaultIncludeDisabledUsers}
					defaultIncludeDisabledSites={defaultIncludeDisabledSites}
					defaultIncludeNeverLoggedUsers={defaultIncludeNeverLoggedUsers}
					defaultStaffTitle={defaultStaffTitle}
					defaultUserName={defaultUserName}
					defaultEmailLogin={defaultEmailLogin}
					OnSelectSites={OnSelectSites}
					onCloseModal={onCloseModal}
					rolesList={rolesList}
					siteList={siteList}
				/>
			)}
			{modalToShow === 'EnableUsersModal' && (
				<EnableUsersModal
					selectedRows={selectedRows}
					fetchUsers={fetchUsers}
					onCloseModal={onCloseModal}
					setSelectedRows={setSelectedRows}
				/>
			)}
			{modalToShow === 'DisableUsersModal' && (
				<DisableUsersModal
					selectedRows={selectedRows}
					fetchUsers={fetchUsers}
					onCloseModal={onCloseModal}
					setSelectedRows={setSelectedRows}
				/>
			)}
			{modalToShow === 'BulkEmailModal' && (
				<BulkEmailModal
					selectedRows={selectedRows}
					fetchUsers={fetchUsers}
					onCloseModal={onCloseModal}
					setSelectedRows={setSelectedRows}
				/>
			)}
			{modalToShow === 'ExportUsersModal' && (
				<ExportUsersModal selectedRows={selectedRows} onCloseModal={onCloseModal} />
			)}
			<div className="row">
				<div className="col-md-4 ">
					<SitesDropdownMultiSelect
						label="Site"
						value={defaultSelectedSites}
						placeholder="Select a Site"
						onChange={OnSelectSites}
						visibleOptions={1}
						allowSelectAll={false}
						sitesList={siteList}
						labelState={false}
					/>
				</div>
				<div className="col-md-3" />
				<div className="col-md-5 users-search-input">
					<SearchBarWithTags
						placeholder="Search user"
						onSearch={OnSearchUsers}
						value={showTagInput ? searchBarTags : defaultUserName}
						onDelete={onDeleteTag}
						onClearSearchBar={onClearSearchBar}
						advancedSearchbtn={true}
						showTagInput={showTagInput}
						advancedSearchbtnAction={() => setModalToShow('AdvancedSearchModal')}
					/>
				</div>
				<div className="col-md-4 ">
					<RolesDropdown
						label="Role Type"
						placeholder="Select a Role"
						value={defaultSelectedRoles}
						onChange={OnSelectRoles}
						options={rolesList}
						visibleOptions={1}
						allowSelectAll={false}
					/>
				</div>
				<div className="col-md-8 d-flex justify-content-between users-actions-row">
					<div className="include-disabled-users">
						<Switch
							label="Include disabled users"
							checked={defaultIncludeDisabledUsers}
							onChange={val => OnIncludeDisabled(val.target.checked)}
						/>
					</div>
					<div className="d-flex">
						{userEditPermission && (
							<>
								<DropdownButton
									className="mr-3 BulkActionsButton__Dropdown"
									style={{
										backgroundColor: 'white',
										color: '#007E8F',
									}}
									variant="white"
									disabled={selectedRows.length > 0 ? false : true}
									lefticon="add"
									text="Bulk Actions"
								>
									<>
										<Dropdown.Item
											className="mt-2"
											onClick={() => setModalToShow('DisableUsersModal')}
										>
											Disable users
										</Dropdown.Item>
										<Dropdown.Item
											className="mt-2"
											onClick={() => setModalToShow('EnableUsersModal')}
										>
											Enable users
										</Dropdown.Item>
										<Dropdown.Item
											className="mt-2"
											onClick={() => setModalToShow('BulkEmailModal')}
										>
											Send bulk email
										</Dropdown.Item>
										<Dropdown.Item
											className="mt-2"
											onClick={() => setModalToShow('assignToSitesModal')}
										>
											Edit building alerts
										</Dropdown.Item>
										<Dropdown.Item
											className="mt-2"
											onClick={() => setModalToShow('ExportUsersModal')}
										>
											Export users
										</Dropdown.Item>
									</>
								</DropdownButton>
								{showAddUserBtn && (
									<AddUserButton setModalToShow={setModalToShow} />
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default UserFilters;
