/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import '@babel/polyfill';
import * as Sentry from '@sentry/react';
// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'react-router-redux';
import { Router } from 'react-router';

import App from 'pages/App';
import { AppUpdateModal } from 'components/AppUpdateModal';

// Load the favicon and the .htaccess file

import './app.scss';
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

import configureStore from './configureStore';

// Initialize Sentry.
Sentry.init({
	dsn: 'https://a41773ed3701a7b2821fc32cd2f5bfb9@o1274041.ingest.us.sentry.io/4508224867467264',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/ems\.navigate360\.com\//],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const initialState = {};
const store = configureStore(initialState, history);
const history = syncHistoryWithStore(createBrowserHistory(), store);
const MOUNT_NODE = document.getElementById('app');

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<App history={history} />
		</Router>
	</Provider>,
	MOUNT_NODE,
);

if (process.env.NODE_ENV !== 'development') {
	// eslint-disable-next-line global-require
	const runtime = require('offline-plugin/runtime');
	runtime.install({
		onUpdating: () => {
			ReactDOM.render(<AppUpdateModal />, MOUNT_NODE);
		},
		onUpdateReady: () => {
			runtime.applyUpdate();
		},
		onUpdated: () => {
			window.location.reload();
		},
	});
}
