import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ActionsButton, Button } from 'componentsV2';
import { MAPS_EDIT_PATH, MAPS_VIEW_PATH, MAP_LIST_PAGE, MapsContext } from '..';

const ActionListButton = ({ text, icon, onClick }) => (
	<Button
		key={text}
		text={text}
		icon={icon}
		size="md"
		type="ghostDefault"
		wrapperClass="displayBlock"
		className="actionButtonElement"
		onClick={onClick}
	/>
);

export const ActionsList = ({ map, sourcePage = null, elementSelector = null, history, isNavigateEmail = false }) => {
	const MapCTX = useContext(MapsContext);

	return (
		<ActionsButton elementSelector={elementSelector}>
			{(map.viewPermission || map.isVisibleToAll) && (
				<>
					{ isNavigateEmail && (
						<ActionListButton
							text="View in same window"
							icon="eye-open"
							onClick={() =>
								history.push('/' + MAPS_VIEW_PATH + '/' + map.building.id + '/' + map.id)
							}
						/>
					)}
					<ActionListButton
						text="View"
						icon="eye-open"
						onClick={() =>
							history.push(
								window.open(
									'/' + MAPS_VIEW_PATH + '/' + map.building.id + '/' + map.id,
									'_blank',
								),
							)
						}
					/>
					<ActionListButton
						text="Map info"
						icon="info-sign"
						onClick={() => {
							MapCTX.getMapDetails(map.id);
						}}
					/>
				</>
			)}
			{map.editPermission && !map.archivedBy && (
				<>
					<ActionListButton
						text="Edit"
						icon="edit"
						onClick={() =>
							history.push(
								window.open(
									'/' + MAPS_EDIT_PATH + '/' + map.building.id + '/' + map.id,
									'_blank',
								),
							)
						}
					/>
					<ActionListButton
						text="Archive"
						icon="archive"
						onClick={() => MapCTX.showArchiveModal([map], MAP_LIST_PAGE)}
					/>
				</>
			)}
			{map.editPermission && map.archivedBy && (
				<ActionListButton
					text="Unarchive"
					icon="unarchive"
					onClick={() => MapCTX.showUnarchiveModal([map], MAP_LIST_PAGE)}
				/>
			)}
			{!map.archivedBy && (
				<>
					<ActionListButton text="Download" icon="cloud-download" onClick={() => MapCTX.showDownloadMapModal(map)} />
				</>
			)}
			{map.editPermission && !map.archivedBy && (
				<ActionListButton
					text="Delete"
					icon="trash"
					onClick={() => MapCTX.showDeleteMapModal([map], MAP_LIST_PAGE)}
				/>
			)}
		</ActionsButton>
	);
};
ActionListButton.propTypes = {
	text: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};

ActionsList.propTypes = {
	map: PropTypes.object,
	sourcePage: PropTypes.element,
	elementSelector: PropTypes.element,
	history: PropTypes.object,
};
